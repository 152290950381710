import axios from "axios";
import {
    GET_ALL_ARTICLES,
    GET_ALL_ARTICLES_UPDATED,
    GET_ALL_ARTICLES_PAGE,
    GET_ALL_ARTICLES_TAG,
    ALL_ARTICLES_ERROR,
    GET_ARTICLE,
    GET_ARTICLE_ERROR,
    GET_RESEARCH_ARTICLES,
    GET_RESEARCH_ARTICLES_ERROR,
    GET_FEATURED_ARTICLES,
    GET_FEATURED_ARTICLES_ERROR,
    GET_CASE_COUNT,
    GET_MARKETING_COUNT,
    SET_FEATURED_ARTICLE,
    GET_ALL_PODCASTS,
    GET_PODCASTS,
    GET_PODCAST_COUNT,
    ADD_PODCAST,
    UPDATE_PODCAST,
    DELETE_PODCAST,
    GET_CE,
    GET_CE_COUNT,
    GET_OTHER,
    GET_OTHER_COUNT,
    GET_INFORMATION,
    GET_INFORMATION_COUNT,
    SELECT_ARTICLE,
    SEARCH_CASE_PAGE,
    SEARCH_MARKETING_PAGE,
    SEARCH_PODCAST_PAGE,
    SEARCH_CE_PAGE,
    SEARCH_OTHER_PAGE,
    SEARCH_INFORMATION_PAGE,
    SET_FEATURED_ARTICLE_ERROR,
    SET_FEATURED_ARTICLES_FALSE,
    SET_FEATURED_ARTICLES_FALSE_ERROR,
    GET_ARTICLE_COUNT,
    GET_ARTICLE_COUNT_ERROR,
    ADD_ARTICLE,
    ADD_ARTICLE_ERROR,
    UPDATE_ARTICLE,
    UPDATE_ARTICLE_TAG,
    DELETE_ARTICLE,
    UPDATE_ARTICLE_ERROR,
    DELETE_ARTICLE_ERROR,
    GET_ALL_ARTICLES_PAGE_ERROR,
    GET_CASE_STUDY_ARTICLES,
    GET_CASE_STUDY_ARTICLES_ERROR,
    GET_MARKETING_MATERIALS_ARTICLES,
    GET_MARKETING_MATERIALS_ARTICLES_ERROR,
    SEARCH_CASE_PAGE_ERROR,
    SEARCH_MARKETING_PAGE_ERROR,
    GET_CASE_COUNT_ERROR,
    GET_MARKETING_COUNT_ERROR,
    GET_FEATURED_EBOOKS,
    SET_FEATURED_EBOOK,
    SET_FEATURED_EBOOKS_FALSE,
    GET_FEATURED_EBOOKS_ERROR,
    SET_FEATURED_EBOOK_ERROR,
    SET_FEATURED_EBOOKS_FALSE_ERROR,
    GET_ALL_PODCASTS_ERROR,
    GET_PODCASTS_ERROR,
    GET_PODCAST_COUNT_ERROR,
    ADD_PODCAST_ERROR,
    UPDATE_PODCAST_ERROR,
    DELETE_PODCAST_ERROR,
    SEARCH_PODCAST_PAGE_ERROR,
    GET_CE_ERROR,
    GET_CE_COUNT_ERROR,
    SEARCH_CE_PAGE_ERROR,
    GET_INFORMATION_ERROR,
    GET_INFORMATION_COUNT_ERROR,
    SEARCH_INFORMATION_PAGE_ERROR,
    GET_OTHER_ERROR,
    GET_OTHER_COUNT_ERROR,
    SEARCH_OTHER_PAGE_ERROR,
    GET_ARTICLE_PRODUCTS,
    GET_ARTICLE_PRODUCTS_ERROR,
    UPDATE_ARTICLE_PRODUCT_MAP,
    UPDATE_ARTICLE_PRODUCT_MAP_ERROR,
    DELETE_ARTICLE_PRODUCT_ASSOCIATION,
    DELETE_ARTICLE_PRODUCT_ASSOCIATION_ERROR,
    GET_EBOOKS,
    GET_EBOOKS_ERROR,
    SEARCH_ALL_ARTICLES_PAGE,
    SEARCH_ALL_ARTICLES_PAGE_ERROR,
    UNPUBLISH_ARTICLES,
    UNPUBLISH_ARTICLES_ERROR,
    GET_ALL_FEATURED_RECENT_ARTICLES,
    GET_FEATURED_RECENT_ARTICLES_BY_CATEGORY,
    DELETE_FEATURED_RECENT_ARTICLES,
    ADD_FEATURED_RECENT_ARTICLE,
    GET_ALL_FEATURED_RECENT_ARTICLES_ERROR,
    GET_FEATURED_RECENT_ARTICLES_BY_CATEGORY_ERROR,
    DELETE_FEATURED_RECENT_ARTICLES_ERROR,
    ADD_FEATURED_RECENT_ARTICLE_ERROR,
    GET_ALL_URL_TITLES,
    SEARCH_PUBLISHED_ARTICLES,
    SEARCH_PUBLISHED_ARTICLES_ERROR,
} from "./types";
import { setAlert } from "./alerts";
import { selectArticleForTemplate } from "./articleTemplates";

const baseUrl = process.env.REACT_APP_BASE_URL;

//gets all articles
export const getArticles = () => async (dispatch) => {
    try {
        const response = await axios.get(`${baseUrl}/api/articles`);

        dispatch({
            type: GET_ALL_ARTICLES,
            payload: response.data,
        });
    } catch (err) {
        dispatch({
            type: ALL_ARTICLES_ERROR,
            payload: { msg: "Could not fetch all articles" },
        });
    }
};

//gets all articles for tag/seos
export const getArticlesTag = () => async (dispatch) => {
    try {
        const response = await axios.get(`${baseUrl}/api/articles/tag`);

        dispatch({
            type: GET_ALL_ARTICLES_TAG,
            payload: response.data,
        });
    } catch (err) {
        dispatch({
            type: ALL_ARTICLES_ERROR,
            payload: { msg: "Could not fetch all articles" },
        });
    }
};

//gets all recently updated manufacturers
export const getArticlesUpdated = () => async (dispatch) => {
    try {
        const response = await axios.get(`${baseUrl}/api/articles/tag/recent`);

        dispatch({
            type: GET_ALL_ARTICLES_UPDATED,
            payload: response.data,
        });
    } catch (err) {
        dispatch({
            type: ALL_ARTICLES_ERROR,
            payload: { msg: "Could not fetch all manufacturers" },
        });
    }
};

//gets the total count of articles
export const getArticleCount = () => async (dispatch) => {
    try {
        const response = await axios.get(`${baseUrl}/api/articles/count`);

        dispatch({
            type: GET_ARTICLE_COUNT,
            payload: response.data,
        });
    } catch (err) {
        dispatch({
            type: GET_ARTICLE_COUNT_ERROR,
            payload: { msg: "Could not fetch the article count" },
        });
    }
};

//gets the total count of case study
export const getCaseCount = () => async (dispatch) => {
    try {
        const response = await axios.get(`${baseUrl}/api/articles/count-case`);

        dispatch({
            type: GET_CASE_COUNT,
            payload: response.data,
        });
    } catch (err) {
        dispatch({
            type: GET_CASE_COUNT_ERROR,
            payload: { msg: "Could not fetch the case count" },
        });
    }
};

//gets the total count of marketing materials
export const getMarketingCount = () => async (dispatch) => {
    try {
        const response = await axios.get(`${baseUrl}/api/articles/count-marketing`);

        dispatch({
            type: GET_MARKETING_COUNT,
            payload: response.data,
        });
    } catch (err) {
        dispatch({
            type: GET_MARKETING_COUNT_ERROR,
            payload: { msg: "Could not fetch the marketing count" },
        });
    }
};

//gets the total count of podcasts
export const getPodcastCount = () => async (dispatch) => {
    try {
        const response = await axios.get(`${baseUrl}/api/articles/count-podcast`);

        dispatch({
            type: GET_PODCAST_COUNT,
            payload: response.data,
        });
    } catch (err) {
        dispatch({
            type: GET_PODCAST_COUNT_ERROR,
            payload: { msg: "Could not fetch the podcast count" },
        });
    }
};

//delete podcast
export const deletePodcast = (id, podcast, page) => async (dispatch) => {
    try {
        const response = await axios.delete(`${baseUrl}/api/articles/delete-podcast/${id}`);

        dispatch({
            type: DELETE_PODCAST,
            payload: response.data,
        });

        dispatch(getPodcasts(page));
    } catch (err) {
        dispatch({
            type: DELETE_PODCAST_ERROR,
            payload: { msg: "Could not delete podcast" },
        });
    }
}

//gets the total count of continuing education articles
export const getCECount = () => async (dispatch) => {
    try {
        const response = await axios.get(`${baseUrl}/api/articles/count-ce`);

        dispatch({
            type: GET_CE_COUNT,
            payload: response.data,
        });
    } catch (err) {
        dispatch({
            type: GET_CE_COUNT_ERROR,
            payload: { msg: "Could not fetch the podcast count" },
        });
    }
};

//gets the total count of information articles
export const getInformationCount = () => async (dispatch) => {
    try {
        const response = await axios.get(
            `${baseUrl}/api/articles/count-information`
        );

        dispatch({
            type: GET_INFORMATION_COUNT,
            payload: response.data,
        });
    } catch (err) {
        dispatch({
            type: GET_INFORMATION_COUNT_ERROR,
            payload: { msg: "Could not fetch the information count" },
        });
    }
};

//gets the total count of other articles
export const getOtherCount = () => async (dispatch) => {
    try {
        const response = await axios.get(`${baseUrl}/api/articles/count-other`);

        dispatch({
            type: GET_OTHER_COUNT,
            payload: response.data,
        });
    } catch (err) {
        dispatch({
            type: GET_OTHER_COUNT_ERROR,
            payload: { msg: "Could not fetch the article count" },
        });
    }
};

//gets all articles paginated
export const getArticlesPage = (page) => async (dispatch) => {
    try {
        const response = await axios.get(`${baseUrl}/api/articles/page/${page}/25`);

        dispatch({
            type: GET_ALL_ARTICLES_PAGE,
            payload: response.data,
        });
    } catch (err) {
        dispatch({
            type: GET_ALL_ARTICLES_PAGE_ERROR,
            payload: { msg: "Could not fetch all articles" },
        });
    }
};

//gets article by id
export const getArticle = (id) => async (dispatch) => {
    try {
        const response = await axios.get(`${baseUrl}/api/articles/article/${id}`);

        dispatch({
            type: GET_ARTICLE,
            payload: response.data,
        });
    } catch (err) {
        dispatch({
            type: GET_ARTICLE_ERROR,
            payload: { msg: "Could not fetch article" },
        });
    }
};

//gets article by id
export const getArticleProducts = (id) => async (dispatch) => {
    try {
        const response = await axios.get(`${baseUrl}/api/articles/article-map/${id}`);

        dispatch({
            type: GET_ARTICLE_PRODUCTS,
            payload: response.data,
        });
    } catch (err) {
        dispatch({
            type: GET_ARTICLE_PRODUCTS_ERROR,
            payload: { msg: "Could not fetch article products" },
        });
    }
};

//Add new article
export const addArticle = (article, page, productList) => async (dispatch) => {
    try {
        const response = await axios.post(`${baseUrl}/api/articles/`, {
            article,
            productList
        });

        dispatch({
            type: ADD_ARTICLE,
            payload: response.data,
        });

        if (article.is_templated) {
            dispatch(selectArticleForTemplate(response.data))
        }
        else if (article.article_type === "Case Study") {
            dispatch(getCaseStudyArticles(page));
        } else if (article.article_type === "Research") {
            dispatch(getResearchArticles());
        } else if (article.article_type === "Marketing Materials") {
            dispatch(getMarketingMaterialsArticles(page));
        } else if (article.article_type === "CE" || article.article_type === "Viva") {
            dispatch(getCE(page));
        } else if (article.article_type === "Information") {
            dispatch(getInformation(page));
        } else if (article.article_type === "Podcast") {
            dispatch(getPodcasts(page));
        } else {
            dispatch(getOther(page));
        }
        dispatch(setAlert("Article added successfully!", "success"));
    } catch (err) {
        dispatch(setAlert("Failed to add article", "danger"));
        dispatch({
            type: ADD_ARTICLE_ERROR,
            payload: { msg: "Could not add article" },
        });
    }
};

//update article by id
export const updateArticle = (id, article, page) => async (dispatch) => {
    try {
        const response = await axios.put(`${baseUrl}/api/articles/article/${id}`, {
            article,
        });

        dispatch({
            type: UPDATE_ARTICLE,
            payload: response.data,
        });
        dispatch(setAlert("Article successfully updated!", "success"));

        if (article.article_type === "Case Study") {
            dispatch(getCaseStudyArticles(page));
        } else if (article.article_type === "Research") {
            dispatch(getResearchArticles());
        } else if (article.article_type === "Marketing Materials") {
            dispatch(getMarketingMaterialsArticles(page));
        } else if (article.article_type === "CE" || article.article_type === "Viva") {
            dispatch(getCE(page));
        } else if (article.article_type === "Information") {
            dispatch(getInformation(page));
        } else if (article.article_type === "Podcast") {
            dispatch(getPodcasts(page));
        } else {
            dispatch(getOther(page));
        }
    } catch (err) {
        dispatch(setAlert("Unable to update article", "danger"));
        dispatch({
            type: UPDATE_ARTICLE_ERROR,
            payload: { msg: "Could not update article" },
        });
    }
};

export const addArticleProductAssociation = (productList) => async (dispatch) => {
    try {
        const response = await axios.post(`${baseUrl}/api/articles/article-product-map/`, {
            productList
        });

        dispatch({
            type: UPDATE_ARTICLE_PRODUCT_MAP,
            payload: response.data,
        });
        dispatch(setAlert("Article Map successfully updated!", "success"));

    } catch (err) {
        dispatch(setAlert("Unable to update article map", "danger"));
        dispatch({
            type: UPDATE_ARTICLE_PRODUCT_MAP_ERROR,
            payload: { msg: "Could not update article map" },
        });
    }
}

//delete podcast
export const deleteArticleProductAssociation = (id, prodId) => async (dispatch) => {
    try {
        const response = await axios.delete(`${baseUrl}/api/articles/article-product-map/${id}/${prodId}`);

        dispatch({
            type: DELETE_ARTICLE_PRODUCT_ASSOCIATION,
            payload: response.data,
        });

    } catch (err) {
        dispatch({
            type: DELETE_ARTICLE_PRODUCT_ASSOCIATION_ERROR,
            payload: { msg: "Could not delete article product association" },
        });
    }
}

//update article tag by id
export const updateArticleTag = (id, article, page) => async (dispatch) => {
    try {
        const response = await axios.put(`${baseUrl}/api/articles/article/${id}`, {
            article,
        });

        dispatch({
            type: UPDATE_ARTICLE_TAG,
            payload: response.data,
        });
        dispatch(setAlert("Article successfully updated!", "success"));
        dispatch(getArticles());
    } catch (err) {
        dispatch(setAlert("Unable to update article", "danger"));
        dispatch({
            type: UPDATE_ARTICLE_ERROR,
            payload: { msg: "Could not update article" },
        });
    }
};

//delete product by id
export const deleteArticle = (id, article, page) => async (dispatch) => {
    try {
        const response = await axios.delete(`${baseUrl}/api/articles/${id}`);

        dispatch({
            type: DELETE_ARTICLE,
            payload: response.data,
        });
        if (article.article_type === "Case Study") {
            dispatch(getCaseStudyArticles(page));
        } else if (article.article_type === "Research") {
            dispatch(getResearchArticles());
        } else if (article.article_type === "Marketing Materials") {
            dispatch(getMarketingMaterialsArticles(page));
        } else if (article.article_type === "CE" || article.article_type === "Viva") {
            dispatch(getCE(page));
        } else if (article.article_type === "Information") {
            dispatch(getInformation(page));
        } else if (article.article_type === "Podcast") {
            dispatch(getPodcasts(page));
        } else {
            dispatch(getOther(page));
        }
        dispatch(setAlert("Article successfully deleted!", "success"));
    } catch (err) {
        dispatch(setAlert("Unable to delete article", "danger"));
        dispatch({
            type: DELETE_ARTICLE_ERROR,
            payload: { msg: "Could not delete article" },
        });
    }
};

//add new podcast
export const addPodcast = (podcast, page) => async (dispatch) => {
    try {
        const response = await axios.post(`${baseUrl}/api/articles/add-podcast`, {
            podcast,
        });

        dispatch({
            type: ADD_PODCAST,
            payload: response.data,
        });

        dispatch(getPodcasts(page));
        dispatch(setAlert("Podcast added successfully!", "success"));
    } catch (err) {
        dispatch(setAlert("Failed to add podcast", "danger"));
        dispatch({
            type: ADD_PODCAST_ERROR,
            payload: { msg: "Could not add podcast" },
        });
    }
}

//update podcast
export const updatePodcast = (id, podcast, page) => async (dispatch) => {
    try {
        const response = await axios.put(`${baseUrl}/api/articles/podcast/${id}`, {
            podcast,
        });

        dispatch({
            type: UPDATE_PODCAST,
            payload: response.data,
        });
        dispatch(setAlert("Podcast successfully updated!", "success"));
        dispatch(getPodcasts(page));

    } catch (err) {
        dispatch(setAlert("Unable to update podcast", "danger"));
        dispatch({
            type: UPDATE_PODCAST,
            payload: { msg: "Could not update podcast" },
        });
    }
};

//GET gets all research articles
export const getResearchArticles = () => async (dispatch) => {
    try {
        const response = await axios.get(
            `${baseUrl}/api/articles/research-details`
        );

        dispatch({
            type: GET_RESEARCH_ARTICLES,
            payload: response.data,
        });
    } catch (err) {
        dispatch({
            type: GET_RESEARCH_ARTICLES_ERROR,
            payload: { msg: "Could not fetch all research articles" },
        });
    }
};

//GET gets all case study articles
export const getCaseStudyArticles = (page) => async (dispatch) => {
    try {
        const response = await axios.get(`${baseUrl}/api/articles/case/${page}/25`);

        dispatch({
            type: GET_CASE_STUDY_ARTICLES,
            payload: response.data,
        });
    } catch (err) {
        dispatch({
            type: GET_CASE_STUDY_ARTICLES_ERROR,
            payload: { msg: "Could not fetch all case study articles" },
        });
    }
};

//GET gets all marketing materials articles
export const getMarketingMaterialsArticles = (page) => async (dispatch) => {
    try {
        const response = await axios.get(
            `${baseUrl}/api/articles/marketing/${page}/25`
        );

        dispatch({
            type: GET_MARKETING_MATERIALS_ARTICLES,
            payload: response.data,
        });
    } catch (err) {
        dispatch({
            type: GET_MARKETING_MATERIALS_ARTICLES_ERROR,
            payload: { msg: "Could not fetch all marketing materials articles" },
        });
    }
};

//GET gets podcasts with page
export const getPodcasts = (page) => async (dispatch) => {
    try {
        const response = await axios.get(
            `${baseUrl}/api/articles/podcast/${page}/25`
        );
        dispatch({
            type: GET_PODCASTS,
            payload: response.data,
        });
    } catch (err) {
        dispatch({
            type: GET_PODCASTS_ERROR,
            payload: { msg: "Could not fetch all podcasts" },
        });
    }
};

//GET gets all podcasts
export const getAllPodcasts = () => async (dispatch) => {
    try {
        const response = await axios.get(
            `${baseUrl}/api/articles/podcasts/`
        );
        dispatch({
            type: GET_ALL_PODCASTS,
            payload: response.data,
        });
    } catch (err) {
        dispatch({
            type: GET_ALL_PODCASTS_ERROR,
            payload: { msg: "Could not fetch all podcasts" },
        });
    }
};

//GET gets all continuing education
export const getCE = (page) => async (dispatch) => {
    try {
        const response = await axios.get(`${baseUrl}/api/articles/ce/${page}/25`);

        dispatch({
            type: GET_CE,
            payload: response.data,
        });
    } catch (err) {
        dispatch({
            type: GET_CE_ERROR,
            payload: { msg: "Could not fetch all continuing education articles" },
        });
    }
};

//GET gets all information
export const getInformation = (page) => async (dispatch) => {
    try {
        const response = await axios.get(
            `${baseUrl}/api/articles/information/${page}/25`
        );

        dispatch({
            type: GET_INFORMATION,
            payload: response.data,
        });
    } catch (err) {
        dispatch({
            type: GET_INFORMATION_ERROR,
            payload: { msg: "Could not fetch all information articles" },
        });
    }
};

//GET gets all other articles
export const getOther = (page) => async (dispatch) => {
    try {
        const response = await axios.get(
            `${baseUrl}/api/articles/other/${page}/25`
        );

        dispatch({
            type: GET_OTHER,
            payload: response.data,
        });
    } catch (err) {
        dispatch({
            type: GET_OTHER_ERROR,
            payload: { msg: "Could not fetch all other articles" },
        });
    }
};

//search case articles with pagination and count
export const searchAllArticlesPage = (article, page) => async (dispatch) => {
    try {
        const response = await axios.get(
            `${baseUrl}/api/articles/search-articles/${article}/${page}/25`
        );
        dispatch({
            type: SEARCH_ALL_ARTICLES_PAGE,
            payload: response.data,
        });
    } catch (err) {
        dispatch({
            type: SEARCH_ALL_ARTICLES_PAGE_ERROR,
            payload: [],
        });
    }
};

export const searchPublishedArticles = (article) => async (dispatch) => {
    try {
        const response = await axios.get(
            `${baseUrl}/api/articles/search-articles-published/${article}`
        );
        dispatch({
            type: SEARCH_PUBLISHED_ARTICLES,
            payload: response.data,
        });
    } catch (err) {
        dispatch({
            type: SEARCH_PUBLISHED_ARTICLES_ERROR,
            payload: [],
        });
    }
};

//search case articles with pagination and count
export const searchCasePage = (article, page) => async (dispatch) => {
    try {
        const response = await axios.get(
            `${baseUrl}/api/articles/casesearchpage/${article}/${page}/25`
        );
        dispatch({
            type: SEARCH_CASE_PAGE,
            payload: response.data,
        });
    } catch (err) {
        dispatch({
            type: SEARCH_CASE_PAGE_ERROR,
            payload: [],
        });
    }
};

//search marketing articles with pagination and count
export const searchMarketingPage = (article, page) => async (dispatch) => {
    try {
        const response = await axios.get(
            `${baseUrl}/api/articles/marketingsearchpage/${article}/${page}/25`
        );
        dispatch({
            type: SEARCH_MARKETING_PAGE,
            payload: response.data,
        });
    } catch (err) {
        dispatch({
            type: SEARCH_MARKETING_PAGE_ERROR,
            payload: [],
        });
    }
};

//search podcasts with pagination and count
export const searchPodcasts = (article, page) => async (dispatch) => {
    try {
        const response = await axios.get(
            `${baseUrl}/api/articles/podcastsearchpage/${article}/${page}/25`
        );
        dispatch({
            type: SEARCH_PODCAST_PAGE,
            payload: response.data,
        });
    } catch (err) {
        dispatch({
            type: SEARCH_PODCAST_PAGE_ERROR,
            payload: [],
        });
    }
};

//search contuing education with pagination and count
export const searchCE = (article, page) => async (dispatch) => {
    try {
        const response = await axios.get(
            `${baseUrl}/api/articles/cesearchpage/${article}/${page}/25`
        );
        dispatch({
            type: SEARCH_CE_PAGE,
            payload: response.data,
        });
    } catch (err) {
        dispatch({
            type: SEARCH_CE_PAGE_ERROR,
            payload: [],
        });
    }
};

//search information with pagination and count
export const searchInformation = (article, page) => async (dispatch) => {
    try {
        const response = await axios.get(
            `${baseUrl}/api/articles/informationsearchpage/${article}/${page}/25`
        );
        dispatch({
            type: SEARCH_INFORMATION_PAGE,
            payload: response.data,
        });
    } catch (err) {
        dispatch({
            type: SEARCH_INFORMATION_PAGE_ERROR,
            payload: [],
        });
    }
};

//search other with pagination and count
export const searchOther = (article, page) => async (dispatch) => {
    try {
        const response = await axios.get(
            `${baseUrl}/api/articles/othersearchpage/${article}/${page}/25`
        );
        dispatch({
            type: SEARCH_OTHER_PAGE,
            payload: response.data,
        });
    } catch (err) {
        dispatch({
            type: SEARCH_OTHER_PAGE_ERROR,
            payload: [],
        });
    }
};

//gets all featured articles
export const getFeaturedArticles = () => async (dispatch) => {
    try {
        const response = await axios.get(`${baseUrl}/api/articles/featured`);

        dispatch({
            type: GET_FEATURED_ARTICLES,
            payload: response.data,
        });
    } catch (err) {
        dispatch({
            type: GET_FEATURED_ARTICLES_ERROR,
            payload: { msg: "Could not fetch all featured articles" },
        });
    }
};

//set content as featured for homepage
export const setFeaturedArticles = (featuredArticles) => async (dispatch) => {
    try {
        const response = await axios.put(
            `${baseUrl}/api/articles/set-new-featured/`,
            {
                featuredArticles,
            }
        );

        dispatch({
            type: SET_FEATURED_ARTICLE,
            payload: response.data,
        });
        dispatch(setAlert("Article added as featured succesfully!", "success"));
        dispatch(getFeaturedArticles());
    } catch (err) {
        dispatch(setAlert("Could not set article as featured", "danger"));
        dispatch({
            type: SET_FEATURED_ARTICLE_ERROR,
            payload: { msg: "Could not set article as featured" },
        });
    }
};

// export const setFeaturedArticle = (id, featuredArticle) => async (dispatch) => {
//   try {
//     const response = await axios.put(
//       `${baseUrl}/api/articles/set-new-featured/${id}`,
//       {
//         featuredArticle,
//       }
//     );

//     dispatch({
//       type: SET_FEATURED_ARTICLE,
//       payload: response.data,
//     });
//     dispatch(setAlert("Article added as featured succesfully!", "success"));
//     // dispatch(getFeaturedArticles());
//   } catch (err) {
//     dispatch(setAlert("Could not set article as featured", "danger"));
//     dispatch({
//       type: SET_FEATURED_ARTICLE_ERROR,
//       payload: { msg: "Could not set article as featured" },
//     });
//   }
// };

//set all previously featured products as false
export const setFeaturedArticlesFalse = () => async (dispatch) => {
    try {
        const response = await axios.put(
            `${baseUrl}/api/articles/set-featured-false`
        );

        dispatch({
            type: SET_FEATURED_ARTICLES_FALSE,
            payload: response.data,
        });
        dispatch(getFeaturedArticles());
    } catch (err) {
        dispatch({
            type: SET_FEATURED_ARTICLES_FALSE_ERROR,
            payload: { msg: "Could not remove articles as featured" },
        });
    }
};

//set published articles to false
export const unpublishArticles = (articleIdArray) => async (dispatch) => {
    try {
        const response = await axios.put(
            `${baseUrl}/api/articles/bulkunpublish`, { articleIdArray }
        );

        dispatch({
            type: UNPUBLISH_ARTICLES,
            payload: response.data,
        });
    } catch (err) {
        dispatch({
            type: UNPUBLISH_ARTICLES_ERROR,
            payload: { msg: "Could not unpublish articles" },
        });
    }
};

//selects a single article
export const selectArticle = (article) => (dispatch) => {
    dispatch({
        type: SELECT_ARTICLE,
        payload: article,
    });
};

// EBOOK routes
//gets all featured ebooks
export const getFeaturedEbooks = () => async (dispatch) => {
    try {
        const response = await axios.get(`${baseUrl}/api/articles/featured-ebook`);

        dispatch({
            type: GET_FEATURED_EBOOKS,
            payload: response.data,
        });
    } catch (err) {
        dispatch({
            type: GET_FEATURED_EBOOKS_ERROR,
            payload: { msg: "Could not fetch all featured ebooks" },
        });
    }
};

export const getEbooks = () => async (dispatch) => {
    try {
        const response = await axios.get(`${baseUrl}/api/articles/ebook`);

        dispatch({
            type: GET_EBOOKS,
            payload: response.data,
        });
    } catch (err) {
        dispatch({
            type: GET_EBOOKS_ERROR,
            payload: { msg: "Could not fetch all published ebooks" },
        });
    }
}

//set content as featured for homepage
export const setFeaturedEbook = (id, featuredEbook, isLast) => async (dispatch) => {
    try {
        const response = await axios.put(
            `${baseUrl}/api/articles/set-featured-ebook/${id}`,
            {
                featuredEbook,
            }
        );

        dispatch({
            type: SET_FEATURED_EBOOK,
            payload: response.data,
        });

        if (isLast) {
            dispatch(setAlert("Ebook added as featured succesfully!", "success"));
            dispatch(getFeaturedEbooks());
        }
    } catch (err) {
        dispatch(setAlert("Could not set ebook as featured", "danger"));
        dispatch({
            type: SET_FEATURED_EBOOK_ERROR,
            payload: { msg: "Could not set ebook as featured" },
        });
    }
};

//set all previously featured products as false
export const setFeaturedEbooksFalse = () => async (dispatch) => {
    try {
        const response = await axios.put(
            `${baseUrl}/api/articles/set-featured-ebook-false`
        );

        dispatch({
            type: SET_FEATURED_EBOOKS_FALSE,
            payload: response.data,
        });
        // dispatch(getFeaturedEbooks());
    } catch (err) {
        dispatch({
            type: SET_FEATURED_EBOOKS_FALSE_ERROR,
            payload: { msg: "Could not remove ebooks as featured" },
        });
    }
};


// Featured Recent Articles calls
export const getAllFeaturedRecentArticles = () => async (dispatch) => {
    try {
        const response = await axios.get(`${baseUrl}/api/articles/all-featured-recent-articles`);

        dispatch({
            type: GET_ALL_FEATURED_RECENT_ARTICLES,
            payload: response.data,
        });
    } catch (err) {
        dispatch({
            type: GET_ALL_FEATURED_RECENT_ARTICLES_ERROR,
            payload: { msg: "Could not fetch all featured recent articles" },
        });
    }
};

export const getFeaturedRecentArticlesByCategoryId = (categoryId) => async (dispatch) => {
    try {
        const response = await axios.get(`${baseUrl}/api/articles/featured-recent-articles/${categoryId}`);

        dispatch({
            type: GET_FEATURED_RECENT_ARTICLES_BY_CATEGORY,
            payload: response.data,
        });
    } catch (err) {
        dispatch({
            type: GET_FEATURED_RECENT_ARTICLES_BY_CATEGORY_ERROR,
            payload: { msg: "Could not fetch all featured recent articles" },
        });
    }
};

export const deleteFeaturedRecentArticles = (categoryId) => async (dispatch) => {
    try {
        const response = await axios.delete(`${baseUrl}/api/articles/delete-featured-recent-articles/${categoryId}`);

        dispatch({
            type: DELETE_FEATURED_RECENT_ARTICLES,
            payload: response.data,
        });
        dispatch(getAllFeaturedRecentArticles())
        dispatch(setAlert("Categories recent articles deleted uccessfully!", "success"));
    } catch (err) {
        dispatch({
            type: DELETE_FEATURED_RECENT_ARTICLES_ERROR,
            payload: { msg: "Could not delete featured recent articles" },
        });
    }
}

export const addFeaturedRecentArticle = (articleId, categoryId) => async (dispatch) => {
    try {
        const response = await axios.post(`${baseUrl}/api/articles/feature-recent-article/${articleId}/${categoryId}`, {

        });

        dispatch({
            type: ADD_FEATURED_RECENT_ARTICLE,
            payload: response.data,
        });
        dispatch(getAllFeaturedRecentArticles())
        dispatch(setAlert("Featured Recent Article added successfully!", "success"));
    } catch (err) {
        dispatch(setAlert("Failed to add Featured Recent Article", "danger"));
        dispatch({
            type: ADD_FEATURED_RECENT_ARTICLE_ERROR,
            payload: { msg: "Could not add Featured Recent Article" },
        });
    }
}


export const getAllUrlTitles = () => async (dispatch) => {
    try {
        const response = await axios.get(`${baseUrl}/api/articles/all-url-titles`)
        dispatch({
            type: GET_ALL_URL_TITLES,
            payload: response.data,
        });
    } catch (err) {
        console.log('err', err)
    }
}
