//Auth
export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const GET_USER = "GET_USER";
export const GET_ALL_USERS = "GET_ALL_USERS";
export const GET_ALL_USERS_PAGE = "GET_ALL_USERS_PAGE";
export const GET_ALL_ROLES = "GET_ALL_ROLES";
export const GET_ROLE = "GET_ROLE";
export const GET_PERMISSIONS = "GET_PERMISSIONS";
export const REGISTER = "REGISTER";
export const ADD_ROLE = "ADD_ROLE";
export const UPDATE_ROLE = "UPDATE_ROLE";
export const UPDATE_STATUS = "UPDATE_STATUS";
export const ADD_PERMISSION = "ADD_PERMISSION";
export const DELETE_USER = "DELETE_USER";
export const DELETE_ROLE = "DELETE_ROLE";
export const DELETE_PERMISSIONS = "DELETE_PERMISSIONS";
export const SELECT_USER = "SELECT_USER";
export const UPDATE_USER = "UPDATE_USER";
export const UPDATE_PASSWORD = "UPDATE_PASSWORD";
export const UPDATE_ROLE_NAME = "UPDATE_ROLE_NAME";
export const UPDATE_IMAGE = "UPDATE_IMAGE";
export const LOGIN_ERROR = "LOGIN_ERROR";
export const LOGOUT_ERROR = "LOGOUT_ERROR";
export const REGISTER_ERROR = "REGISTER_ERROR";
export const ADD_ROLE_ERROR = "ADD_ROLE_ERROR";
export const DELETE_ROLE_ERROR = "DELETE_ROLE_ERROR";
export const DELETE_PERMISSIONS_ERROR = "DELETE_PERMISSIONS_ERROR";
export const ADD_PERMISSION_ERROR = "ADD_PERMISSION_ERROR";
export const GET_USER_ERROR = "GET_USER_ERROR";
export const GET_ALL_USERS_ERROR = "GET_ALL_USERS_ERROR";
export const GET_ALL_ROLES_ERROR = "GET_ALL_ROLES_ERROR";
export const GET_ROLE_ERROR = "GET_ROLE_ERROR";
export const GET_PERMISSIONS_ERROR = "GET_PERMISSIONS_ERROR";
export const UPDATE_ROLE_ERROR = "UPDATE_ROLE_ERROR";
export const UPDATE_STATUS_ERROR = "UPDATE_STATUS_ERROR";
export const DELETE_USER_ERROR = "DELETE_USER_ERROR";
export const UPDATE_ROLE_NAME_ERROR = "UPDATE_ROLE_NAME_ERROR";
export const UPDATE_USER_ERROR = "UPDATE_USER_ERROR";
export const UPDATE_PASSWORD_ERROR = "UPDATE_PASSWORD_ERROR";
export const GET_ALL_USERS_PAGE_ERROR = "GET_ALL_USERS_PAGE_ERROR";
export const UPDATE_IMAGE_ERROR = "UPDATE_IMAGE_ERROR";

//alerts
export const SET_ALERT = "SET_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";

//Products
export const GET_ALL_PRODUCTS = "GET_ALL_PRODUCTS";
export const GET_ALL_PRODUCTS_UPDATED = "GET_ALL_PRODUCTS_UPDATED";
export const SELECT_PRODUCT_UPDATED = "SELECT_PRODUCT_UPDATED";
export const GET_ALL_PRODUCTS_PAGE = "GET_ALL_PRODUCTS_PAGE";
export const GET_PRODUCT = "GET_PRODUCT";
export const GET_PRODUCT_COUNT = "GET_PRODUCT_COUNT";
export const SELECT_PRODUCT = "SELECT_PRODUCT";
export const SEARCH_PRODUCTS = "SEARCH_PRODUCTS";
export const SEARCH_PRODUCTS_PAGE = "SEARCH_PRODUCTS_PAGE";
export const ADD_PRODUCT = "ADD_PRODUCT";
export const UPDATE_PRODUCT = "UPDATE_PRODUCT";
export const UPDATE_PRODUCT_TAG = "UPDATE_PRODUCT_TAG";
export const DELETE_PRODUCT = "DELETE_PRODUCT";
export const GET_FEATURED_PRODUCTS = "GET_FEATURED_PRODUCTS";
export const SET_FEATURED_PRODUCT = "SET_FEATURED_PRODUCT";
export const SET_FEATURED_FALSE = "SET_FEATURED_FALSE";
export const GET_NEW_FEATURED_PRODUCTS = "GET_NEW_FEATURED_PRODUCTS";
export const SET_NEW_FEATURED_PRODUCT = "SET_NEW_FEATURED_PRODUCT";
export const SET_NEW_FEATURED_FALSE = "SET_NEW_FEATURED_FALSE";
export const GET_NEW_DEFAULT_PRODUCTS = "GET_NEW_DEFAULT_PRODUCTS";
export const SET_NEW_DEFAULT_PRODUCT = "SET_NEW_DEFAULT_PRODUCT";
export const SET_NEW_DEFAULT_FALSE = "SET_NEW_DEFAULT_FALSE";
export const ALL_PRODUCTS_ERROR = "ALL_PRODUCTS_ERROR";
export const GET_PRODUCT_ERROR = "GET_PRODUCT_ERROR";
export const GET_PRODUCT_COUNT_ERROR = "GET_PRODUCT_COUNT_ERROR";
export const ADD_PRODUCT_ERROR = "ADD_PRODUCT_ERROR";
export const UPDATE_PRODUCT_ERROR = "UPDATE_PRODUCT_ERROR";
export const DELETE_PRODUCT_ERROR = "DELETE_PRODUCT_ERROR";
export const GET_ALL_PRODUCTS_PAGE_ERROR = "GET_ALL_PRODUCTS_PAGE_ERROR";
export const SEARCH_PRODUCTS_ERROR = "SEARCH_PRODUCTS_ERROR";
export const SEARCH_PRODUCTS_PAGE_ERROR = "SEARCH_PRODUCTS_PAGE_ERROR";
export const GET_FEATURED_PRODUCTS_ERROR = "GET_FEATURED_PRODUCTS_ERROR";
export const SET_FEATURED_PRODUCT_ERROR = "SET_FEATURED_PRODUCT_ERROR";
export const SET_FEATURED_FALSE_ERROR = "SET_FEATURED_FALSE_ERROR";
export const GET_NEW_FEATURED_PRODUCTS_ERROR =
    "GET_NEW_FEATURED_PRODUCTS_ERROR";
export const SET_NEW_FEATURED_PRODUCT_ERROR = "SET_NEW_FEATURED_PRODUCT_ERROR";
export const SET_NEW_FEATURED_FALSE_ERROR = "SET_NEW_FEATURED_FALSE_ERROR";
export const GET_NEW_DEFAULT_PRODUCTS_ERROR = "GET_NEW_DEFAULT_PRODUCTS_ERROR";
export const SET_NEW_DEFAULT_PRODUCT_ERROR = "SET_NEW_DEFAULT_PRODUCT_ERROR";
export const SET_NEW_DEFAULT_FALSE_ERROR = "SET_NEW_DEFAULT_FALSE_ERROR";

//Product Images
export const UPDATE_PRODUCT_IMAGES = "UPDATE_PRODUCT_IMAGES";
export const UPDATE_PRODUCT_IMAGES_ERROR = "UPDATE_PRODUCT_IMAGES_ERROR";
export const GET_PRODUCT_IMAGES = "GET_PRODUCT_IMAGES";
export const GET_PRODUCT_IMAGES_ERROR = "GET_PRODUCT_IMAGES_ERROR";
export const DELETE_PRODUCT_IMAGES = "DELETE_PRODUCT_IMAGES";
export const DELETE_PRODUCT_IMAGES_ERROR = "DELETE_PRODUCT_IMAGES_ERROR";
export const ADD_PRODUCT_IMAGES = "ADD_PRODUCT_IMAGES";
export const ADD_PRODUCT_IMAGES_ERROR = "ADD_PRODUCT_IMAGES_ERROR";

//Categories
export const SELECT_CATEGORY = "SELECT_CATEGORY";
export const GET_ALL_CATEGORIES = "GET_ALL_CATEGORIES";
export const GET_ALL_CATEGORIES_UPDATED = "GET_ALL_CATEGORIES_UPDATED";
export const GET_ALL_CATEGORIES_PAGE = "GET_ALL_CATEGORIES_PAGE";
export const GET_CATEGORY = "GET_CATEGORY";
export const GET_CATEGORY_COUNT = "GET_CATEGORY_COUNT";
export const ADD_CATEGORY = "ADD_CATEGORY";
export const UPDATE_CATEGORY = "UPDATE_CATEGORY";
export const UPDATE_CATEGORY_TAG = "UPDATE_CATEGORY_TAG";
export const DELETE_CATEGORY = "DELETE_CATEGORY";
export const DELETE_CATEGORY_ERROR = "DELETE_CATEGORY_ERROR";
export const UPDATE_CATEGORY_ERROR = "UPDATE_CATEGORY_ERROR";
export const ADD_CATEGORY_ERROR = "ADD_CATEGORY_ERROR";
export const GET_CATEGORY_ERROR = "GET_CATEGORY_ERROR";
export const ALL_CATEGORIES_ERROR = "ALL_CATEGORIES_ERROR";
export const GET_CATEGORY_COUNT_ERROR = "GET_CATEGORY_COUNT_ERROR";
export const GET_ALL_CATEGORIES_PAGE_ERROR = "GET_ALL_CATEGORIES_PAGE_ERROR";
export const SET_FEATURED_CATEGORY = "SET_FEATURED_CATEGORY";
export const SET_FEATURED_CATEGORIES_FALSE = "SET_FEATURED_CATEGORIES_FALSE";
export const GET_FEATURED_CATEGORIES = "GET_FEATURED_CATEGORIES";
export const SET_FEATURED_CATEGORY_ERROR = "SET_FEATURED_CATEGORY_ERROR";
export const SET_FEATURED_CATEGORIES_FALSE_ERROR =
    "SET_FEATURED_CATEGORIES_FALSE_ERROR";
export const GET_FEATURED_CATEGORIES_ERROR = "GET_FEATURED_CATEGORIES_ERROR";
export const CATEGORY_SEARCH = "CATEGORY_SEARCH";
export const CATEGORY_SEARCH_ERROR = "CATEGORY_SEARCH_ERROR";

//Articles
export const GET_ALL_ARTICLES = "GET_ALL_ARTICLES";
export const GET_ALL_ARTICLES_UPDATED = "GET_ALL_ARTICLES_UPDATED";
export const GET_ALL_ARTICLES_TAG = "GET_ALL_ARTICLES_TAG";
export const GET_ALL_ARTICLES_PAGE = "GET_ALL_ARTICLES_PAGE";
export const GET_ARTICLE = "GET_ARTICLE";
export const GET_ARTICLE_COUNT = "GET_ARTICLE_COUNT";
export const GET_CASE_COUNT = "GET_ARTICLE_COUNT";
export const GET_MARKETING_COUNT = "GET_ARTICLE_COUNT";
export const GET_RESEARCH_ARTICLES = "GET_RESEARCH_ARTICLES";
export const GET_CASE_STUDY_ARTICLES = "GET_CASE_STUDY_ARTICLES";
export const GET_MARKETING_MATERIALS_ARTICLES =
    "GET_MARKETING_MATERIALS_ARTICLES";
export const GET_CE = "GET_CE";
export const GET_CE_COUNT = "GET_CE_COUNT";
export const GET_OTHER = "GET_OTHER";
export const GET_OTHER_COUNT = "GET_OTHER_COUNT";
export const GET_PODCASTS = "GET_PODCASTS";
export const GET_ALL_PODCASTS = "GET_ALL_PODCASTS";
export const GET_PODCAST_COUNT = "GET_PODCAST_COUNT";
export const ADD_PODCAST = "ADD_PODCAST";
export const UPDATE_PODCAST = "UPDATE_PODCAST";
export const DELETE_PODCAST = "DELETE_PODCAST";
export const GET_INFORMATION = "GET_INFORMATION";
export const GET_INFORMATION_COUNT = "GET_INFORMATION_COUNT";
export const GET_FEATURED_ARTICLES = "GET_FEATURED_ARTICLES";
export const SET_FEATURED_ARTICLE = "SET_FEATURED_ARTICLE";
export const SET_FEATURED_ARTICLES_FALSE = "SET_FEATURED_ARTICLES_FALSE";
export const GET_FEATURED_EBOOKS = "GET_FEATURED_EBOOKS";
export const SET_FEATURED_EBOOK = "SET_FEATURED_EBOOK";
export const SET_FEATURED_EBOOKS_FALSE = "SET_FEATURED_EBOOKS_FALSE";
export const ADD_ARTICLE = "ADD_ARTICLE";
export const SELECT_ARTICLE = "SELECT_ARTICLE";
export const UPDATE_ARTICLE = "UPDATE_ARTICLE";
export const UPDATE_ARTICLE_TAG = "UPDATE_ARTICLE_TAG";
export const DELETE_ARTICLE = "DELETE_ARTICLE";
export const SEARCH_CASE_PAGE = "SEARCH_CASE_PAGE";
export const SEARCH_MARKETING_PAGE = "SEARCH_MARKETING_PAGE";
export const SEARCH_PODCAST_PAGE = "SEARCH_PODCAST_PAGE";
export const SEARCH_INFORMATION_PAGE = "SEARCH_INFORMATION_PAGE";
export const SEARCH_CE_PAGE = "SEARCH_CE_PAGE";
export const SEARCH_OTHER_PAGE = "SEARCH_OTHER_PAGE";
export const DELETE_ARTICLE_ERROR = "DELETE_ARTICLE_ERROR";
export const UPDATE_ARTICLE_ERROR = "UPDATE_ARTICLE_ERROR";
export const ADD_ARTICLE_ERROR = "ADD_ARTICLE_ERROR";
export const GET_ARTICLE_ERROR = "GET_ARTICLE_ERROR";
export const ALL_ARTICLES_ERROR = "ALL_ARTICLES_ERROR";
export const GET_ARTICLE_COUNT_ERROR = "GET_ARTICLE_COUNT_ERROR";
export const GET_ALL_ARTICLES_PAGE_ERROR = "GET_ALL_ARTICLES_PAGE_ERROR";
export const GET_RESEARCH_ARTICLES_ERROR = "GET_RESEARCH_ARTICLES_ERROR";
export const GET_CASE_STUDY_ARTICLES_ERROR = "GET_CASE_STUDY_ARTICLES_ERROR";
export const SET_FEATURED_ARTICLE_ERROR = "SET_FEATURED_ARTICLE_ERROR";
export const SET_FEATURED_ARTICLES_FALSE_ERROR =
    "SET_FEATURED_ARTICLES_FALSE_ERROR";
export const GET_MARKETING_MATERIALS_ARTICLES_ERROR =
    "GET_MARKETING_MATERIALS_ARTICLES_ERROR";
export const GET_FEATURED_ARTICLES_ERROR = "GET_FEATURED_ARTICLES_ERROR";
export const SEARCH_CASE_PAGE_ERROR = "SEARCH_CASE_PAGE_ERROR";
export const SEARCH_MARKETING_PAGE_ERROR = "SEARCH_MARKETING_PAGE_ERROR";
export const GET_CASE_COUNT_ERROR = "GET_ARTICLE_COUNT_ERROR";
export const GET_MARKETING_COUNT_ERROR = "GET_ARTICLE_COUNT_ERROR";
export const GET_FEATURED_EBOOKS_ERROR = "GET_FEATURED_EBOOKS_ERROR";
export const SET_FEATURED_EBOOK_ERROR = "SET_FEATURED_EBOOK_ERROR";
export const SET_FEATURED_EBOOKS_FALSE_ERROR =
    "SET_FEATURED_EBOOKS_FALSE_ERROR";
export const GET_PODCASTS_ERROR = "GET_PODCASTS_ERROR";
export const GET_PODCAST_COUNT_ERROR = "GET_PODCAST_COUNT_ERROR";
export const ADD_PODCAST_ERROR = "ADD_PODCAST_ERROR";
export const UPDATE_PODCAST_ERROR = "UPDATE_PODCAST_ERROR";
export const DELETE_PODCAST_ERROR = "DELETE_PODCAST_ERROR";
export const SEARCH_PODCAST_PAGE_ERROR = "SEARCH_PODCAST_PAGE_ERROR";
export const GET_CE_ERROR = "GET_CE_ERROR";
export const GET_CE_COUNT_ERROR = "GET_CE_COUNT_ERROR";
export const SEARCH_CE_PAGE_ERROR = "SEARCH_CE_PAGE_ERROR";
export const GET_INFORMATION_ERROR = "GET_INFORMATION_ERROR";
export const GET_INFORMATION_COUNT_ERROR = "GET_INFORMATION_COUNT_ERROR";
export const SEARCH_INFORMATION_PAGE_ERROR = "SEARCH_INFORMATION_PAGE_ERROR";
export const GET_OTHER_ERROR = "GET_OTHER_ERROR";
export const GET_OTHER_COUNT_ERROR = "GET_OTHER_COUNT_ERROR";
export const SEARCH_OTHER_PAGE_ERROR = "SEARCH_OTHER_PAGE_ERROR";
export const GET_ALL_PODCASTS_ERROR = "GET_ALL_PODCASTS_ERROR";
export const GET_ARTICLE_PRODUCTS = "GET_ARTICLE_PRODUCTS";
export const GET_ARTICLE_PRODUCTS_ERROR = "GET_ARTICLE_PRODUCTS_ERROR";
export const UPDATE_ARTICLE_PRODUCT_MAP = "UPDATE_ARTICLE_PRODUCT_MAP";
export const UPDATE_ARTICLE_PRODUCT_MAP_ERROR = "UPDATE_ARTICLE_PRODUCT_MAP_ERROR";
export const DELETE_ARTICLE_PRODUCT_ASSOCIATION = "DELETE_ARTICLE_PRODUCT_ASSOCIATION";
export const DELETE_ARTICLE_PRODUCT_ASSOCIATION_ERROR = "DELETE_ARTICLE_PRODUCT_ASSOCIATION_ERROR";
export const GET_EBOOKS = "GET_EBOOKS";
export const GET_EBOOKS_ERROR = "GET_EBOOKS_ERROR";
export const SEARCH_ALL_ARTICLES_PAGE = "SEARCH_ALL_ARTICLES_PAGE";
export const SEARCH_ALL_ARTICLES_PAGE_ERROR = "SEARCH_ALL_ARTICLES_PAGE_ERROR";
export const GET_ALL_URL_TITLES = "GET_ALL_URL_TITLES";
export const SEARCH_PUBLISHED_ARTICLES = "SEARCH_PUBLISHED_ARTICLES";
export const SEARCH_PUBLISHED_ARTICLES_ERROR = "SEARCH_PUBLISHED_ARTICLES_ERROR";

// Article Types
export const GET_ALL_ARTICLE_TYPES = "GET_ALL_ARTICLE_TYPES";
export const GET_ALL_ARTICLE_TYPES_ERROR = "GET_ALL_ARTICLE_TYPES_ERROR";

// Article Templates
export const GET_TEMPLATES = "GET_TEMPLATES";
export const GET_TEMPLATES_ERROR = "GET_TEMPLATES_ERROR";
export const SET_HAS_TEMPLATE = "SET_HAS_TEMPLATE";
export const SET_HAS_TEMPLATE_ERROR = "SET_HAS_TEMPLATE_ERROR";
export const SELECT_ARTICLE_TEMPLATES = "SELECT_ARTICLE_TEMPLATES";
export const SELECT_ARTICLE_FOR_TEMPLATE = "SELECT_ARTICLE_FOR_TEMPLATE";
export const SELECT_BLOG_FOR_TEMPLATE = "SELECT_BLOG_FOR_TEMPLATE";
export const SELECT_EVALUATION_FOR_TEMPLATE = "SELECT_EVALUATION_FOR_TEMPLATE";
export const SET_HAS_TEMPLATE_SECTION = "SET_HAS_TEMPLATE_SECTION";
export const SELECT_ARTICLE_TEMPLATE_SECTION = "SELECT_ARTICLE_TEMPLATE_SECTION";
export const CHANGE_INPUT_DATA = "CHANGE_INPUT_DATA";
export const GET_ARTICLE_TITLE = "GET_ARTICLE_TITLE";
export const GET_ARTICLE_TITLE_ERROR = "GET_ARTICLE_TITLE_ERROR";
export const SAVE_ARTICLE = "SAVE_ARTICLE";
export const SAVE_ARTICLE_ERROR = "SAVE_ARTICLE_ERROR";
export const SAVE_TEMPLATE = "SAVE_TEMPLATE";
export const SAVE_TEMPLATE_ERROR = "SAVE_TEMPLATE_ERROR";
export const SET_TEMPLATE_RETURN_URL = "SET_TEMPLATE_RETURN_URL";
export const DELETE_TEMPLATE = "DELETE_TEMPLATE";
export const DELETE_TEMPLATE_ERROR = "DELETE_TEMPLATE_ERROR";
export const SELECT_ARTICLE_TEMPLATE_INPUT = "SELECT_ARTICLE_TEMPLATE_INPUT";
export const CHANGE_TEMPLATE_HISTORY = "CHANGE_TEMPLATE_HISTORY";
export const CLEAR_TEMPLATE_HISTORY = "CLEAR_TEMPLATE_HISTORY";
export const ADD_TEMPLATE_HISTORY = "ADD_TEMPLATE_HISTORY";
export const CLEAR_TEMPLATE = "CLEAR_TEMPLATE";
export const SHOW_PREVIEW = "SHOW_PREVIEW";
export const SHOW_PREVIEW_ERROR = "SHOW_PREVIEW_ERROR";

//Featured products
export const GET_ALL_FEATURED = "GET_ALL_FEATURED";
export const GET_ALL_FEATURED_PAGE = "GET_ALL_FEATURED_PAGE";
export const GET_FEATURED = "GET_FEATURED";
export const GET_FEATURED_COUNT = "GET_FEATURED_COUNT";
export const ADD_FEATURED = "ADD_FEATURED";
export const SELECT_FEATURED = "SELECT_FEATURED";
export const UPDATE_FEATURED = "UPDATE_FEATURED";
export const DELETE_FEATURED = "DELETE_FEATURED";
export const DELETE_FEATURED_ERROR = "DELETE_FEATURED_ERROR";
export const UPDATE_FEATURED_ERROR = "UPDATE_FEATURED_ERROR";
export const ADD_FEATURED_ERROR = "ADD_FEATURED_ERROR";
export const GET_FEATURED_ERROR = "GET_FEATURED_ERROR";
export const ALL_FEATURED_ERROR = "ALL_FEATURED_ERROR";
export const SELECT_FEATURED_ERROR = "SELECT_FEATURED_ERROR";
export const GET_FEATURED_COUNT_ERROR = "GET_FEATURED_COUNT_ERROR";
export const GET_ALL_FEATURED_PAGE_ERROR = "GET_ALL_FEATURED_PAGE_ERROR";

//Featured manufacturers
export const GET_ALL_FEATURED_MAN = "GET_ALL_FEATURED_MAN";
export const GET_ALL_FEATURED_PAGE_MAN = "GET_ALL_FEATURED_PAGE_MAN";
export const GET_FEATURED_MAN = "GET_FEATURED_MAN";
export const GET_FEATURED_COUNT_MAN = "GET_FEATURED_COUNT_MAN";
export const ADD_FEATURED_MAN = "ADD_FEATURED_MAN";
export const SELECT_FEATURED_MAN = "SELECT_FEATURED_MAN";
export const UPDATE_FEATURED_MAN = "UPDATE_FEATURED_MAN";
export const DELETE_FEATURED_MAN = "DELETE_FEATURED_MAN";
export const DELETE_FEATURED_MAN_ERROR = "DELETE_FEATURED_MAN_ERROR";
export const UPDATE_FEATURED_MAN_ERROR = "UPDATE_FEATURED_MAN_ERROR";
export const ADD_FEATURED_MAN_ERROR = "ADD_FEATURED_MAN_ERROR";
export const GET_FEATURED_MAN_ERROR = "GET_FEATURED_MAN_ERROR";
export const ALL_FEATURED_MAN_ERROR = "ALL_FEATURED_MAN_ERROR";
export const SELECT_FEATURED_MAN_ERROR = "SELECT_FEATURED_MAN_ERROR";
export const GET_FEATURED_COUNT_MAN_ERROR = "GET_FEATURED_COUNT_MAN_ERROR";
export const GET_ALL_FEATURED_PAGE_MAN_ERROR =
    "GET_ALL_FEATURED_PAGE_MAN_ERROR";

//manufacturers
export const GET_ALL_MANUFACTURERS = "GET_ALL_MANUFACTURERS";
export const GET_ALL_MANUFACTURERS_UPDATED = "GET_ALL_MANUFACTURERS_UPDATED";
export const GET_ALL_MANUFACTURERS_PAGE = "GET_ALL_MANUFACTURERS_PAGE";
export const GET_MANUFACTURER = "GET_MANUFACTURER";
export const SELECT_MANUFACTURER = "SELECT_MANUFACTURER";
export const GET_MANUFACTURERS_AD = "GET_MANUFACTURERS_AD";
export const GET_MANUFACTURER_COUNT = "GET_MANUFACTURER_COUNT";
export const ADD_MANUFACTURER = "ADD_MANUFACTURER";
export const SEARCH_MANUFACTURERS = "SEARCH_MANUFACTURERS";
export const UPDATE_MANUFACTURER = "UPDATE_MANUFACTURER";
export const DELETE_MANUFACTURER = "DELETE_MANUFACTURER";
export const DELETE_MANUFACTURER_ERROR = "DELETE_MANUFACTURER_ERROR";
export const UPDATE_MANUFACTURER_ERROR = "UPDATE_MANUFACTURER_ERROR";
export const GET_MANUFACTURERS_AD_ERROR = "GET_MANUFACTURERS_AD_ERROR";
export const ADD_MANUFACTURER_ERROR = "ADD_MANUFACTURER_ERROR";
export const GET_MANUFACTURER_ERROR = "GET_MANUFACTURER_ERROR";
export const ALL_MANUFACTURERS_ERROR = "ALL_MANUFACTURERS_ERROR";
export const GET_MANUFACTURER_COUNT_ERROR = "GET_MANUFACTURER_COUNT_ERROR";
export const SEARCH_MANUFACTURERS_ERROR = "SEARCH_MANUFACTURERS_ERROR";
export const GET_ALL_MANUFACTURERS_PAGE_ERROR =
    "GET_ALL_MANUFACTURERS_PAGE_ERROR";
export const UPDATE_MANUFACTURER_TAGS = "UPDATE_MANUFACTURER_TAGS";
export const UPDATE_MANUFACTURER_TAG_ERROR = "UPDATE_MANUFACTURER_TAG_ERROR";
export const GET_MANUFACTURERS_BY_CATEGORY = "GET_MANUFACTURERS_BY_CATEGORY";
export const GET_MANUFACTURERS_BY_CATEGORY_ERROR = "GET_MANUFACTURERS_BY_CATEGORY_ERROR";

//promotions
export const GET_ALL_PROMOTIONS = "GET_ALL_PROMOTIONS";
export const GET_ALL_PROMOTIONS_PAGE = "GET_ALL_PROMOTIONS_PAGE";
export const GET_PROMOTION = "GET_PROMOTION";
export const GET_PROMOTION_COUNT = "GET_PROMOTION_COUNT";
export const SELECT_PROMOTION = "SELECT_PROMOTION";
export const SEARCH_PROMOTIONS = "SEARCH_PROMOTIONS";
export const ADD_PROMOTION = "ADD_PROMOTION";
export const ADD_PROMOTION_PRODUCTS = "ADD_PROMOTION_PRODUCTS";
export const UPDATE_PROMOTION = "UPDATE_PROMOTION";
export const DELETE_PROMOTION = "DELETE_PROMOTION";
export const GET_NEW_PRODUCT_PROMOTION = "GET_NEW_PRODUCT_PROMOTION";
export const SET_NEW_PRODUCT_PROMOTION = "SET_NEW_PRODUCT_PROMOTION";
export const SET_NEW_PRODUCT_PROMOTION_FALSE =
    "SET_NEW_PRODUCT_PROMOTION_FALSE";
export const DELETE_PROMOTION_ERROR = "DELETE_PROMOTION_ERROR";
export const UPDATE_PROMOTION_ERROR = "UPDATE_PROMOTION_ERROR";
export const ADD_PROMOTION_ERROR = "ADD_PROMOTION_ERROR";
export const GET_PROMOTION_ERROR = "GET_PROMOTION_ERROR";
export const ALL_PROMOTIONS_ERROR = "ALL_PROMOTIONS_ERROR";
export const ALL_PROMOTIONS_PAGE_ERROR = "ALL_PROMOTIONS_ERROR";
export const GET_PROMOTION_COUNT_ERROR = "GET_PROMOTION_COUNT_ERROR";
export const GET_ALL_PROMOTIONS_PAGE_ERROR = "GET_ALL_PROMOTIONS_PAGE_ERROR";
export const SEARCH_PROMOTIONS_ERROR = "SEARCH_PROMOTIONS_ERROR";
export const ADD_PROMOTION_PRODUCTS_ERROR = "ADD_PROMOTION_PRODUCTS_ERROR";
export const GET_NEW_PRODUCT_PROMOTION_ERROR =
    "GET_NEW_PRODUCT_PROMOTION_ERROR";
export const SET_NEW_PRODUCT_PROMOTION_ERROR =
    "SET_NEW_PRODUCT_PROMOTION_ERROR";
export const SET_NEW_PRODUCT_PROMOTION_FALSE_ERROR =
    "SET_NEW_PRODUCT_PROMOTION_FALSE_ERROR";
export const GET_PROMOTION_PAGE_FEATURED = "GET_PROMOTION_PAGE_FEATURED";
export const SET_PROMOTION_PAGE_FEATURED = "SET_PROMOTION_PAGE_FEATURED";
export const SET_PROMOTION_PAGE_FEATURED_FALSE =
    "SET_PROMOTION_PAGE_FEATURED_FALSE";
export const GET_PROMOTION_PAGE_DEFAULT = "GET_PROMOTION_PAGE_DEFAULT";
export const SET_PROMOTION_PAGE_DEFAULT = "SET_PROMOTION_PAGE_DEFAULT";
export const SET_PROMOTION_PAGE_DEFAULT_FALSE =
    "SET_PROMOTION_PAGE_DEFAULT_FALSE";
export const GET_PROMOTION_PAGE_FEATURED_ERROR =
    "GET_PROMOTION_PAGE_FEATURED_ERROR";
export const SET_PROMOTION_PAGE_FEATURED_ERROR =
    "SET_PROMOTION_PAGE_FEATURED_ERROR";
export const SET_PROMOTION_PAGE_FEATURED_FALSE_ERROR =
    "SET_PROMOTION_PAGE_FEATURED_FALSE_ERROR";
export const GET_PROMOTION_PAGE_DEFAULT_ERROR =
    "GET_PROMOTION_PAGE_DEFAULT_ERROR";
export const SET_PROMOTION_PAGE_DEFAULT_ERROR =
    "SET_PROMOTION_PAGE_DEFAULT_ERROR";
export const SET_PROMOTION_PAGE_DEFAULT_FALSE_ERROR =
    "SET_PROMOTION_PAGE_DEFAULT_FALSE_ERROR";
export const GET_ASSOCIATED_PRODUCTS =
    "GET_ASSOCIATED_PRODUCTS";
export const GET_ASSOCIATED_PRODUCTS_ERROR =
    "GET_ASSOCIATED_PRODUCTS_ERROR";
export const DELETE_PRODUCT_ASSOCIATION =
    "DELETE_PRODUCT_ASSOCIATION";
export const DELETE_PRODUCT_ASSOCIATION_ERROR =
    "DELETE_PRODUCT_ASSOCIATION_ERROR";


// embed forms
export const GET_ALL_EMBED_FORMS = "GET_ALL_EMBED_FORMS"
export const ALL_EMBED_FORMS_ERROR = "ALL_EMBED_FORMS_ERROR"
export const GET_ALL_EMBED_FORMS_PAGE = "GET_ALL_EMBED_FORMS_PAGE"
export const ALL_EMBED_FORMS_PAGE_ERROR = "ALL_EMBED_FORMS_PAGE_ERROR"
export const GET_EMBED_FORM = "GET_EMBED_FORM"
export const GET_EMBED_FORM_ERROR = "GET_EMBED_FORM_ERROR"
export const SELECT_EMBED_FORM = "SELECT_EMBED_FORM"
export const SEARCH_EMBED_FORMS = "SEARCH_EMBED_FORMS"
export const GET_EMBED_FORM_COUNT = "GET_EMBED_FORM_COUNT"
export const GET_EMBED_FORM_COUNT_ERROR = "GET_EMBED_FORM_COUNT_ERROR"
export const ADD_EMBED_FORM = "ADD_EMBED_FORM"
export const ADD_EMBED_FORM_ERROR = "ADD_EMBED_FORM_ERROR"
export const UPDATE_EMBED_FORM = "UPDATE_EMBED_FORM"
export const DELETE_EMBED_FORM = "DELETE_EMBED_FORM"
export const UPDATE_EMBED_FORM_ERROR = "UPDATE_EMBED_FORM_ERROR"
export const DELETE_EMBED_FORM_ERROR = "DELETE_EMBED_FORM_ERROR"
export const SEARCH_EMBED_FORMS_ERROR = "SEARCH_EMBED_FORMS_ERROR"
export const GET_EMBED_FORM_PAGE_DEFAULT = "GET_EMBED_FORM_PAGE_DEFAULT"
export const GET_EMBED_FORM_PAGE_FEATURED_ERROR = "GET_EMBED_FORM_PAGE_FEATURED_ERROR"
export const GET_EMBED_FORM_PAGE_DEFAULT_ERROR = "GET_EMBED_FORM_PAGE_DEFAULT_ERROR"


//advertisement
export const GET_ALL_ADVERTISEMENTS = "GET_ALL_ADVERTISEMENTS";
export const GET_ALL_ADVERTISEMENTS_PAGE = "GET_ALL_ADVERTISEMENTS_PAGE";
export const GET_ADVERTISEMENT = "GET_ADVERTISEMENT";
export const GET_ADVERTISEMENT_COUNT = "GET_ADVERTISEMENT_COUNT";
export const GET_ADVERTISEMENT_MANUFACTURER = "GET_ADVERTISEMENT_MANUFACTURER";
export const ADD_ADVERTISEMENT = "ADD_ADVERTISEMENT";
export const UPDATE_ADVERTISEMENT = "UPDATE_ADVERTISEMENT";
export const DELETE_ADVERTISEMENT = "DELETE_ADVERTISEMENT";
export const SELECT_ADVERTISEMENT = "SELECT_ADVERTISEMENT";
export const DELETE_ADVERTISEMENT_ERROR = "DELETE_ADVERTISEMENT_ERROR";
export const UPDATE_ADVERTISEMENT_ERROR = "UPDATE_ADVERTISEMENT_ERROR";
export const ADD_ADVERTISEMENT_ERROR = "ADD_ADVERTISEMENT_ERROR";
export const GET_ADVERTISEMENT_ERROR = "GET_ADVERTISEMENT_ERROR";
export const ALL_ADVERTISEMENTS_ERROR = "ALL_ADVERTISEMENTS_ERROR";
export const GET_ADVERTISEMENT_COUNT_ERROR = "GET_ADVERTISEMENT_COUNT_ERROR";
export const GET_ALL_ADVERTISEMENTS_PAGE_ERROR =
    "GET_ALL_ADVERTISEMENTS_PAGE_ERROR";
export const GET_ADVERTISEMENT_MANUFACTURER_ERROR =
    "GET_ADVERTISEMENT_MANUFACTURER_ERROR";
export const SEARCH_ADVERTISEMENTS_PAGE = "  SEARCH_ADVERTISEMENTS_PAGE";
export const SEARCH_ADVERTISEMENTS_PAGE_ERROR = "  SEARCH_ADVERTISEMENTS_PAGE_ERROR";
export const GET_ADVERTISEMENTS_CONTENT = "  GET_ADVERTISEMENTS_CONTENT";
export const GET_ADVERTISEMENTS_CONTENT_ERROR = "  GET_ADVERTISEMENTS_CONTENT_ERROR";

//evaluations
export const GET_ALL_EVALUATIONS = "GET_ALL_EVALUATIONS";
export const GET_ALL_EVALUATIONS_UPDATED = "GET_ALL_EVALUATIONS_UPDATED";
export const GET_ALL_EVALUATIONS_TAG = "GET_ALL_EVALUATIONS_TAG";
export const GET_ALL_EVALUATIONS_PAGE = "GET_ALL_EVALUATIONS_PAGE";
export const GET_EVALUATION = "GET_EVALUATION";
export const SELECT_EVALUATION = "SELECT_EVALUATION";
export const GET_EVALUATION_COUNT = "GET_EVALUATION_COUNT";
export const ADD_EVALUATION = "ADD_EVALUATION";
export const SEARCH_EVALUATIONS = "SEARCH_EVALUATIONS";
export const SEARCH_EVALUATIONS_PAGE = "SEARCH_EVALUATIONS_PAGE";
export const UPDATE_EVALUATION = "UPDATE_EVALUATION";
export const UPDATE_EVALUATION_TAG = "UPDATE_EVALUATION_TAG";
export const DELETE_EVALUATION = "DELETE_EVALUATION";
export const DELETE_EVALUATION_ERROR = "DELETE_EVALUATION_ERROR";
export const UPDATE_EVALUATION_ERROR = "UPDATE_EVALUATION_ERROR";
export const GET_EVALUATIONS_AD_ERROR = "GET_EVALUATIONS_AD_ERROR";
export const ADD_EVALUATION_ERROR = "ADD_EVALUATION_ERROR";
export const GET_EVALUATION_ERROR = "GET_EVALUATION_ERROR";
export const ALL_EVALUATIONS_ERROR = "ALL_EVALUATIONS_ERROR";
export const GET_EVALUATION_COUNT_ERROR = "GET_EVALUATION_COUNT_ERROR";
export const SEARCH_EVALUATIONS_ERROR = "SEARCH_EVALUATIONS_ERROR";
export const GET_ALL_EVALUATIONS_PAGE_ERROR = "GET_ALL_EVALUATIONS_PAGE_ERROR";
export const SEARCH_EVALUATIONS_PAGE_ERROR = "SEARCH_EVALUATIONS_PAGE_ERROR";
export const SET_FEATURED_EVALUATION = "SET_FEATURED_EVALUATION";
export const SET_FEATURED_EVALUATIONS_FALSE = "SET_FEATURED_EVALUATIONS_FALSE";
export const GET_FEATURED_EVALUATIONS = "GET_FEATURED_EVALUATIONS";
export const SET_FEATURED_EVALUATION_ERROR = "SET_FEATURED_EVALUATION_ERROR";
export const SET_FEATURED_EVALUATIONS_FALSE_ERROR =
    "SET_FEATURED_EVALUATIONS_FALSE_ERROR";
export const GET_FEATURED_EVALUATIONS_ERROR = "GET_FEATURED_EVALUATIONS_ERROR";
export const GET_REVIEW_PAGE_FEATURED = "GET_REVIEW_PAGE_FEATURED";
export const SET_REVIEW_PAGE_FEATURED = "SET_REVIEW_PAGE_FEATURED";
export const SET_REVIEW_PAGE_FEATURED_FALSE = "SET_REVIEW_PAGE_FEATURED_FALSE";
export const GET_REVIEW_PAGE_DEFAULT = "GET_REVIEW_PAGE_DEFAULT";
export const SET_REVIEW_PAGE_DEFAULT = "SET_REVIEW_PAGE_DEFAULT";
export const SET_REVIEW_PAGE_DEFAULT_FALSE = "SET_REVIEW_PAGE_DEFAULT_FALSE";
export const GET_REVIEW_PAGE_FEATURED_ERROR = "GET_REVIEW_PAGE_FEATURED_ERROR";
export const SET_REVIEW_PAGE_FEATURED_ERROR = "SET_REVIEW_PAGE_FEATURED_ERROR";
export const SET_REVIEW_PAGE_FEATURED_FALSE_ERROR =
    "SET_REVIEW_PAGE_FEATURED_FALSE_ERROR";
export const GET_REVIEW_PAGE_DEFAULT_ERROR = "GET_REVIEW_PAGE_DEFAULT_ERROR";
export const SET_REVIEW_PAGE_DEFAULT_ERROR = "SET_REVIEW_PAGE_DEFAULT_ERROR";
export const SET_REVIEW_PAGE_DEFAULT_FALSE_ERROR = "SET_REVIEW_PAGE_DEFAULT_FALSE_ERROR";
export const GET_ALL_FEATURED_EVALS_DL = "GET_ALL_FEATURED_EVALS_DL";
export const GET_ALL_FEATURED_EVALS_DL_ERROR = "GET_ALL_FEATURED_EVALS_DL_ERROR";
export const ADD_FEATURED_EVAL_DL = "ADD_FEATURED_EVAL_DL";
export const ADD_FEATURED_EVAL_DL_ERROR = "ADD_FEATURED_EVAL_DL_ERROR";
export const DELETE_FEATURED_EVALS_DL = "DELETE_FEATURED_EVALS_DL";
export const DELETE_FEATURED_EVALS_DL_ERROR = "DELETE_FEATURED_EVALS_DL_ERROR";

// Product Reviews
export const GET_ALL_PRODUCT_REVIEWS_PAGE = "GET_ALL_PRODUCT_REVIEWS_PAGE";
export const GET_PRODUCT_REVIEW_COUNT = "GET_PRODUCT_REVIEW_COUNT";
export const GET_ALL_PRODUCT_REVIEWS_PAGE_ERROR =
    "GET_ALL_PRODUCT_REVIEWS_PAGE_ERROR";
export const GET_PRODUCT_REVIEW_COUNT_ERROR = "GET_PRODUCT_REVIEW_COUNT_ERROR";
export const SELECT_PRODUCT_REVIEW = "SELECT_PRODUCT_REVIEW";
export const SEARCH_PRODUCT_REVIEWS = "SEARCH_PRODUCT_REVIEWS";
export const SEARCH_PRODUCT_REVIEWS_ERROR = "SEARCH_PRODUCT_REVIEWS_ERROR";
export const UPDATE_PRODUCT_REVIEW = "UPDATE_PRODUCT_REVIEW";
export const ADD_PRODUCT_REVIEW = "ADD_PRODUCT_REVIEW";
export const DELETE_PRODUCT_REVIEW = "DELETE_PRODUCT_REVIEW";
export const UPDATE_PRODUCT_REVIEW_ERROR = "UPDATE_PRODUCT_REVIEW_ERROR";
export const ADD_PRODUCT_REVIEW_ERROR = "ADD_PRODUCT_REVIEW_ERROR";
export const DELETE_PRODUCT_REVIEW_ERROR = "DELETE_PRODUCT_REVIEW_ERROR";
export const GET_PRODUCT_REVIEWS = "GET_PRODUCT_REVIEWS";
export const SET_FEATURED_REVIEW = "SET_FEATURED_REVIEW";
export const SET_FEATURED_REVIEWS_FALSE = "SET_FEATURED_REVIEWS_FALSE";
export const GET_FEATURED_REVIEW = "GET_FEATURED_REVIEW";
export const GET_PRODUCT_REVIEWS_ERROR = "GET_PRODUCT_REVIEWS_ERROR";
export const SET_FEATURED_REVIEW_ERROR = "SET_FEATURED_REVIEW_ERROR";
export const SET_FEATURED_REVIEWS_FALSE_ERROR = "SET_FEATURED_REVIEWS_FALSE_ERROR";
export const GET_FEATURED_REVIEW_ERROR = "GET_FEATURED_REVIEW_ERROR";
export const GET_PRODUCT_REVIEWS_BY_MANUFACTURER_ID_PAGE = "GET_PRODUCT_REVIEWS_BY_MANUFACTURER_ID_PAGE";
export const GET_PRODUCT_REVIEWS_BY_MANUFACTURER_ID_PAGE_ERROR = "GET_PRODUCT_REVIEWS_BY_MANUFACTURER_ID_PAGE_ERROR";

// Manufacturer Reviews
export const GET_ALL_MANUFACTURER_REVIEWS_PAGE = "GET_ALL_MANUFACTURER_REVIEWS_PAGE";
export const GET_ALL_MANUFACTURER_REVIEWS_PAGE_ERROR = "GET_ALL_MANUFACTURER_REVIEWS_PAGE_ERROR";
export const GET_MANUFACTURER_REVIEW_COUNT = "GET_MANUFACTURER_REVIEW_COUNT";
export const GET_MANUFACTURER_REVIEW_COUNT_ERROR = "GET_MANUFACTURER_REVIEW_COUNT_ERROR";
export const SELECT_MANUFACTURER_REVIEW = "SELECT_MANUFACTURER_REVIEW";
export const SEARCH_MANUFACTURER_REVIEWS = "SEARCH_MANUFACTURER_REVIEWS";
export const SEARCH_MANUFACTURER_REVIEWS_ERROR = "SEARCH_MANUFACTURER_REVIEWS_ERROR";
export const ADD_MANUFACTURER_REVIEW = "ADD_MANUFACTURER_REVIEW";
export const ADD_MANUFACTURER_REVIEW_ERROR = "ADD_MANUFACTURER_REVIEW_ERROR";
export const UPDATE_MANUFACTURER_REVIEW = "UPDATE_MANUFACTURER_REVIEW";
export const UPDATE_MANUFACTURER_REVIEW_ERROR = "UPDATE_MANUFACTURER_REVIEW_ERROR";
export const DELETE_MANUFACTURER_REVIEW = "DELETE_MANUFACTURER_REVIEW";
export const DELETE_MANUFACTURER_REVIEW_ERROR = "DELETE_MANUFACTURER_REVIEW_ERROR";
export const GET_FILTERED_MANUFACTURER_REVIEWS = "GET_FILTERED_MANUFACTURER_REVIEWS";
export const GET_FILTERED_MANUFACTURER_REVIEWS_ERROR = "GET_FILTERED_MANUFACTURER_REVIEWS_ERROR";

//Product Videos
export const GET_ALL_PRODUCT_VIDEOS = "GET_ALL_PRODUCT_VIDEOS";
export const GET_ALL_PRODUCT_VIDEOS_TAG = "GET_ALL_PRODUCT_VIDEOS_TAG";
export const GET_ALL_PRODUCT_VIDEOS_UPDATED = "GET_ALL_PRODUCT_VIDEOS_UPDATED";
export const GET_ALL_PRODUCT_VIDEOS_PAGE = "GET_ALL_PRODUCTS_VIDEOS_PAGE";
export const GET_PRODUCT_VIDEO = "GET_PRODUCT_VIDEO";
export const GET_PRODUCT_VIDEOS_COUNT = "GET_PRODUCT_VIDEOS_COUNT";
export const SELECT_PRODUCT_VIDEO = "SELECT_PRODUCT_VIDEO";
export const SEARCH_PRODUCT_VIDEOS = "SEARCH_PRODUCT_VIDEOS";
export const SEARCH_PRODUCT_VIDEOS_PAGE = "SEARCH_PRODUCT_VIDEOS_PAGE";
export const ADD_PRODUCT_VIDEOS = "ADD_PRODUCT_VIDEOS";
export const UPDATE_PRODUCT_VIDEOS = "UPDATE_PRODUCT_VIDEOS";
export const UPDATE_PRODUCT_VIDEOS_TAG = "UPDATE_PRODUCT_VIDEOS_TAG";
export const DELETE_PRODUCT_VIDEOS = "DELETE_PRODUCT_VIDEOS";
export const ALL_PRODUCT_VIDEOS_ERROR = "ALL_PRODUCT_VIDEOS_ERROR";
export const GET_ALL_PRODUCT_VIDEOS_ERROR = "GET_ALL_PRODUCT_VIDEOS_ERROR";
export const GET_PRODUCT_VIDEO_ERROR = "GET_PRODUCT_VIDEOS_ERROR";
export const GET_PRODUCT_VIDEOS_COUNT_ERROR = "GET_PRODUCT_VIDEOS_COUNT_ERROR";
export const ADD_PRODUCT_VIDEOS_ERROR = "ADD_PRODUCT_VIDEOS_ERROR";
export const UPDATE_PRODUCT_VIDEOS_ERROR = "UPDATE_PRODUCT_VIDEOS_ERROR";
export const DELETE_PRODUCT_VIDEOS_ERROR = "DELETE_PRODUCT_VIDEOS_ERROR";
export const GET_ALL_PRODUCT_VIDEOS_PAGE_ERROR =
    "GET_ALL_PRODUCT_VIDEOS_PAGE_ERROR";
export const SEARCH_PRODUCT_VIDEOS_ERROR = "SEARCH_PRODUCT_VIDEOS_ERROR";
export const SEARCH_PRODUCT_VIDEOS_PAGE_ERROR =
    "SEARCH_PRODUCT_VIDEOS_PAGE_ERROR";
export const UPDATE_VIDEO_PRODUCT_MAP = "UPDATE_VIDEO_PRODUCT_MAP";
export const UPDATE_VIDEO_PRODUCT_MAP_ERROR = "UPDATE_VIDEO_PRODUCT_MAP_ERROR";
export const DELETE_VIDEO_PRODUCT_ASSOCIATION = "DELETE_VIDEO_PRODUCT_ASSOCIATION";
export const DELETE_VIDEO_PRODUCT_ASSOCIATION_ERROR = "DELETE_VIDEO_PRODUCT_ASSOCIATION_ERROR";
export const GET_VIDEO_PRODUCTS = "GET_VIDEO_PRODUCTS";
export const GET_VIDEO_PRODUCTS_ERROR = "GET_VIDEO_PRODUCTS_ERROR";

//videos
export const GET_ALL_VIDEOS = "GET_ALL_VIDEOS";
export const SET_FEATURED_VIDEO = "SET_FEATURED_VIDEO";
export const SET_FEATURED_VIDEOS_FALSE = "SET_FEATURED_VIDEOS_FALSE";
export const GET_FEATURED_VIDEOS = "GET_FEATURED_VIDEOS";
export const GET_ALL_VIDEOS_ERROR = "GET_ALL_VIDEOS_ERROR";
export const SET_FEATURED_VIDEO_ERROR = "SET_FEATURED_VIDEO_ERROR";
export const SET_FEATURED_VIDEOS_FALSE_ERROR =
    "SET_FEATURED_VIDEOS_FALSE_ERROR";
export const GET_FEATURED_VIDEOS_ERROR = "GET_FEATURED_VIDEOS_ERROR";


//criteria
export const ADD_CRITERIA = "ADD_CRITERIA"
export const ADD_CRITERIA_ERROR = "ADD_CRITERIA_ERROR"
export const GET_ALL_CRITERIA = "GET_ALL_CRITERIA"
export const ALL_CRITERIA_ERROR = "ALL_CRITERIA_ERROR"

//evaluation criteria
export const ADD_EVALUATION_CRITERIA = "ADD_EVALUATION_CRITERIA"
export const ADD_EVALUATION_CRITERIA_ERROR = "ADD_EVALUATION_CRITERIA_ERROR"
export const GET_EVALUATION_CRITERIA = "GET_EVALUATION_CRITERIA"
export const GET_EVALUATION_CRITERIA_ERROR = "GET_EVALUATION_CRITERIA_ERROR"
export const DELETE_EVALUATION_CRITERIA = "DELETE_EVALUATION_CRITERIA"
export const DELETE_EVALUATION_CRITERIA_ERROR = "DELETE_EVALUATION_CRITERIA_ERROR"
export const DELETE_CRITERIA = "DELETE_CRITERIA"
export const DELETE_CRITERIA_ERROR = "DELETE_CRITERIA_ERROR"
export const SELECT_CRITERIA = "SELECT_CRITERIA"
export const GET_CRITERIA_PAGINATED = "GET_CRITERIA_PAGINATED"
export const CRITERIA_PAGINATED_ERROR = "CRITERIA_PAGINATED_ERROR"
export const GET_CRITERIA_COUNT = "GET_CRITERIA_COUNT"
export const GET_CRITERIA_COUNT_ERROR = "GET_CRITERIA_COUNT_ERROR"

//distributors
export const GET_ALL_DISTRIBUTORS = "GET_ALL_DISTRIBUTORS"
export const ALL_DISTRIBUTORS_ERROR = "ALL_DISTRIBUTORS_ERROR"
export const GET_DISTRIBUTORS_PAGINATED = "GET_DISTRIBUTORS_PAGINATED"
export const SEARCH_DISTRIBUTORS_PAGINATED = "SEARCH_DISTRIBUTORS_PAGINATED"
export const GET_DISTRIBUTORS_COUNT = "GET_DISTRIBUTORS_COUNT"
export const GET_DISTRIBUTORS_SEARCH_COUNT = "GET_DISTRIBUTORS_SEARCH_COUNT"
export const SELECT_DISTRIBUTOR = "SELECT_DISTRIBUTOR"
export const ADD_DISTRIBUTOR = "ADD_DISTRIBUTOR"
export const UPDATE_DISTRIBUTOR = "UPDATE_DISTRIBUTOR"
export const DISTRIBUTORS_ERROR = "DISTRIBUTORS_ERROR"

//product distributors
export const GET_ALL_PRODUCT_DISTRIBUTORS = "GET_ALL_PRODUCT_DISTRIBUTORS"
export const ALL_PRODUCT_DISTRIBUTORS_ERROR = "ALL_PRODUCT_DISTRIBUTORS_ERROR"
export const DELETE_PRODUCT_DISTRIBUTORS = "DELETE_PRODUCT_DISTRIBUTORS"
export const DELETE_PRODUCT_DISTRIBUTORS_ERROR = "DELETE_PRODUCT_DISTRIBUTORS_ERROR"
export const ADD_PRODUCT_DISTRIBUTORS = "ADD_PRODUCT_DISTRIBUTORS"
export const ADD_PRODUCT_DISTRIBUTORS_ERROR = "ADD_PRODUCT_DISTRIBUTORS_ERROR"

//product skus
export const GET_PRODUCT_SKUS = "GET_PRODUCT_SKUS"
export const GET_PRODUCT_SKUS_ERROR = "GET_PRODUCT_SKUS_ERROR"
export const DELETE_PRODUCT_SKUS = "DELETE_PRODUCT_SKUS"
export const DELETE_PRODUCT_SKUS_ERROR = "DELETE_PRODUCT_SKUS_ERROR"
export const ADD_PRODUCT_SKUS = "ADD_PRODUCT_SKUS"
export const ADD_PRODUCT_SKUS_ERROR = "ADD_PRODUCT_SKUS_ERROR"
export const SELECT_SKU = "SELECT_SKU"
export const UPDATE_PRODUCT_SKU = "UPDATE_PRODUCT_SKU"
export const UPDATE_PRODUCT_SKU_ERROR = "UPDATE_PRODUCT_SKU_ERROR"


//sku distributors 
export const ADD_SKU_DISTRIBUTORS = "ADD_SKU_DISTRIBUTORS"
export const ADD_SKU_DISTRIBUTORS_ERROR = "ADD_SKU_DISTRIBUTORS_ERROR"
export const UPDATE_SKU_DISTRIBUTOR = "UPDATE_SKU_DISTRIBUTOR"
export const UPDATE_SKU_DISTRIBUTOR_ERROR = "UPDATE_SKU_DISTRIBUTOR_ERROR"

// blogs
export const GET_ALL_BLOGS = "GET_ALL_BLOGS"
export const GET_ALL_BLOGS_ERROR = "GET_ALL_BLOGS_ERROR"
export const GET_BLOGS_PAGE = "GET_BLOGS_PAGE"
export const GET_BLOGS_PAGE_ERROR = "GET_BLOGS_PAGE_ERROR"
export const ADD_BLOG = "ADD_BLOG"
export const ADD_BLOG_ERROR = "ADD_BLOG_ERROR"
export const UPDATE_BLOG = "UPDATE_BLOG"
export const UPDATE_BLOG_ERROR = "UPDATE_BLOG_ERROR"
export const DELETE_BLOG = "DELETE_BLOG"
export const DELETE_BLOG_ERROR = "DELETE_BLOG_ERROR"
export const SELECT_BLOG = "SELECT_BLOG"
export const GET_BLOG_COUNT = "GET_BLOG_COUNT"
export const GET_BLOG_COUNT_ERROR = "GET_BLOG_COUNT_ERROR"
export const GET_BLOGS_SEARCH_PAGE = "GET_BLOGS_SEARCH_PAGE"
export const GET_BLOGS_SEARCH_PAGE_ERROR = "GET_BLOGS_SEARCH_PAGE_ERROR"
export const SEARCH_PUBLISHED_BLOGS = "SEARCH_PUBLISHED_BLOGS"
export const SEARCH_PUBLISHED_BLOGS_ERROR = "SEARCH_PUBLISHED_BLOGS_ERROR"

//placement page
export const GET_PLACEMENT_ADS = "GET_PLACEMENT_ADS"
export const GET_PLACEMENT_ADS_ERROR = "GET_PLACEMENT_ADS_ERROR"

//unpublish content - outlier page
export const UNPUBLISH_ARTICLES = "UNPUBLISH_ARTICLES"
export const UNPUBLISH_ARTICLES_ERROR = "UNPUBLISH_ARTICLES_ERROR"
export const UNPUBLISH_VIDEOS = "UNPUBLISH_VIDEOS"
export const UNPUBLISH_VIDEOS_ERROR = "UNPUBLISH_VIDEOS_ERROR"
export const UNPUBLISH_EVALUATIONS = "UNPUBLISH_EVALUATIONS"
export const UNPUBLISH_EVALUATIONS_ERROR = "UNPUBLISH_EVALUATIONS_ERROR"

// 404 and 301 pages
export const GET_ALL_404_URLS = "GET_ALL_404_URLS"
export const ALL_404_URLS_ERROR = "ALL_404_URLS_ERROR"
export const GET_ALL_301_REDIRECTS = "GET_ALL_301_REDIRECTS"
export const ALL_301_REDIRECTS_ERROR = "ALL_301_REDIRECTS_ERROR"
export const ADD_301_REDIRECTS_BULK = "ADD_301_REDIRECTS_BULK"
export const ADD_301_REDIRECTS_BULK_ERROR = "ADD_301_REDIRECTS_BULK_ERROR"
export const ADD_301_REDIRECT = "ADD_301_REDIRECT"
export const ADD_301_REDIRECT_ERROR = "ADD_301_REDIRECT_ERROR"

//featured recent articles component
export const GET_ALL_FEATURED_RECENT_ARTICLES = "GET_ALL_FEATURED_RECENT_ARTICLES"
export const GET_ALL_FEATURED_RECENT_ARTICLES_ERROR = "GET_ALL_FEATURED_RECENT_ARTICLES_ERROR"
export const GET_FEATURED_RECENT_ARTICLES_BY_CATEGORY = "GET_FEATURED_RECENT_ARTICLES_BY_CATEGORY"
export const GET_FEATURED_RECENT_ARTICLES_BY_CATEGORY_ERROR = "GET_FEATURED_RECENT_ARTICLES_BY_CATEGORY_ERROR"
export const DELETE_FEATURED_RECENT_ARTICLES = "DELETE_FEATURED_RECENT_ARTICLES"
export const DELETE_FEATURED_RECENT_ARTICLES_ERROR = "DELETE_FEATURED_RECENT_ARTICLES_ERROR"
export const ADD_FEATURED_RECENT_ARTICLE = "ADD_FEATURED_RECENT_ARTICLE"
export const ADD_FEATURED_RECENT_ARTICLE_ERROR = "ADD_FEATURED_RECENT_ARTICLE_ERROR"

//featured category content
export const GET_ALL_FEATURED_CATEGORY_CONTENT = "GET_ALL_FEATURED_CATEGORY_CONTENT"
export const GET_ALL_FEATURED_CATEGORY_CONTENT_ERROR = "GET_ALL_FEATURED_CATEGORY_CONTENT_ERROR"
export const DELETE_FEATURED_CATEGORY_CONTENT = "DELETE_FEATURED_CATEGORY_CONTENT"
export const DELETE_FEATURED_CATEGORY_CONTENT_ERROR = "DELETE_FEATURED_CATEGORY_CONTENT_ERROR"
export const DELETE_FEATURED_CATEGORY_CONTENT_BY_TYPE = "DELETE_FEATURED_CATEGORY_CONTENT_BY_TYPE"
export const DELETE_FEATURED_CATEGORY_CONTENT_BY_TYPE_ERROR = "DELETE_FEATURED_CATEGORY_CONTENT_BY_TYPE_ERROR"
export const ADD_FEATURED_CATEGORY_CONTENT = "ADD_FEATURED_CATEGORY_CONTENT"
export const ADD_FEATURED_CATEGORY_CONTENT_ERROR = "ADD_FEATURED_CATEGORY_CONTENT_ERROR"

// toggle site
export const SELECT_SITE = "SELECT_SITE"


//buyers guide
export const ADD_BUYERS_GUIDE = "ADD_BUYERS_GUIDE"
export const ADD_BUYERS_GUIDE_ERROR = "ADD_BUYERS_GUIDE_ERROR"
export const GET_SPECIFIC_BUYERS_GUIDE = "GET_SPECIFIC_BUYERS_GUIDE"
export const GET_SPECIFIC_BUYERS_GUIDE_ERROR = "GET_SPECIFIC_BUYERS_GUIDE_ERROR"
export const GET_ALL_BUYERS_GUIDE = "GET_ALL_BUYERS_GUIDE"
export const ALL_BUYERS_GUIDE_ERROR = "ALL_BUYERS_GUIDE_ERROR"
export const DELETE_BUYERS_GUIDE = "DELETE_BUYERS_GUIDE"
export const DELETE_BUYERS_GUIDE_ERROR = "DELETE_BUYERS_GUIDE_ERROR"
export const SELECT_BUYERS_GUIDE = "SELECT_BUYERS_GUIDE"
export const GET_BUYERS_GUIDE_PAGINATED = "GET_BUYERS_GUIDE_PAGINATED"
export const BUYERS_GUIDE_PAGINATED_ERROR = "BUYERS_GUIDE_PAGINATED_ERROR"
export const GET_BUYERS_GUIDE_COUNT = "GET_BUYERS_GUIDE_COUNT"
export const GET_BUYERS_GUIDE_COUNT_ERROR = "GET_BUYERS_GUIDE_COUNT_ERROR"
export const GET_ALL_BUYERS_GUIDE_CATEGORIES = "GET_ALL_BUYERS_GUIDE_CATEGORIES"
export const GET_ALL_BUYERS_GUIDE_CATEGORIES_ERROR = "GET_ALL_BUYERS_GUIDE_CATEGORIES_ERROR"
export const GET_ALL_BUYERS_GUIDE_MANUFACTURERS = "GET_ALL_BUYERS_GUIDE_MANUFACTURERS"
export const GET_ALL_BUYERS_GUIDE_MANUFACTURERS_ERROR = "GET_ALL_BUYERS_GUIDE_MANUFACTURERS_ERROR"
export const GET_ALL_BUYERS_GUIDE_PRODUCTS = "GET_ALL_BUYERS_GUIDE_PRODUCTS"
export const GET_ALL_BUYERS_GUIDE_PRODUCTS_ERROR = "GET_ALL_BUYERS_GUIDE_PRODUCTS_ERROR"
export const GET_BUYERS_GUIDE_DROPBOX_IMAGES = "GET_BUYERS_GUIDE_DROPBOX_IMAGES"
export const GET_BUYERS_GUIDE_DROPBOX_IMAGES_ERROR = "GET_BUYERS_GUIDE_DROPBOX_IMAGES_ERROR"
export const DROPBOX_IMAGE_LOADED = "DROPBOX_IMAGE_LOADED"
export const DROPBOX_IMAGE_LOADED_ERROR = "DROPBOX_IMAGE_LOADED_ERROR"
export const DROPBOX_IMAGE_LOADING_DONE = "DROPBOX_IMAGE_LOADING_DONE"
export const SAVE_DROPBOX_IMAGE = "SAVE_DROPBOX_IMAGE"
export const SAVE_DROPBOX_IMAGE_ERROR = "SAVE_DROPBOX_IMAGE_ERROR"
export const GET_DROPBOX_AUTH = "GET_DROPBOX_AUTH"
export const GET_DROPBOX_AUTH_ERROR = "GET_DROPBOX_AUTH_ERROR"

// Dynamic Persona
export const ADD_DYNAMIC_PERSONA_LIST_ERROR = "ADD_DYNAMIC_PERSONA_LIST_ERROR"
export const GET_DYNAMIC_PERSONA_LISTS = "GET_DYNAMIC_PERSONA_LISTS"
export const GET_CATEGORY_PERSONA_LISTS = "GET_CATEGORY_PERSONA_LISTS"
export const GET_MANUFACTURER_PERSONA_LISTS = "GET_MANUFACTURER_PERSONA_LISTS"
export const SELECT_DYNAMIC_PERSONA = "SELECT_DYNAMIC_PERSONA"
export const ADD_DYNAMIC_PERSONA_LIST = "ADD_DYNAMIC_PERSONA_LIST"
export const UPDATE_DYNAMIC_PERSONA_LIST = "UPDATE_DYNAMIC_PERSONA_LIST"
export const DELETE_DYNAMIC_PERSONA_LIST = "DELETE_DYNAMIC_PERSONA_LIST"
export const DYNAMIC_PERSONA_LISTS_ERROR = "DYNAMIC_PERSONA_LISTS_ERROR"

// Newsfeed
export const GET_FEATURED_NEWSFEED = "GET_FEATURED_NEWSFEED"
export const GET_FEATURED_NEWSFEED_ERROR = "GET_FEATURED_NEWSFEED_ERROR"
export const ADD_FEATURED_NEWSFEED = "ADD_FEATURED_NEWSFEED"
export const ADD_FEATURED_NEWSFEED_ERROR = "ADD_FEATURED_NEWSFEED_ERROR"

// Team Members
export const GET_MEMBERS_PAGINATED = "GET_MEMBERS_PAGINATED"
export const GET_MEMBERS_PAGINATED_ERROR = "GET_MEMBERS_PAGINATED_ERROR"
export const GET_TEAM_MEMBER_BY_ID = "GET_TEAM_MEMBER_BY_ID"
export const GET_TEAM_MEMBER_BY_ID_ERROR = "GET_TEAM_MEMBER_BY_ID_ERROR"
export const CREATE_TEAM_MEMBER = "CREATE_TEAM_MEMBER"
export const CREATE_TEAM_MEMBER_ERROR = "CREATE_TEAM_MEMBER_ERROR"
export const DELETE_TEAM_MEMBER = "DELETE_TEAM_MEMBER"
export const DELETE_TEAM_MEMBER_ERROR = "DELETE_TEAM_MEMBER_ERROR"
export const UPDATE_TEAM_MEMBER = "UPDATE_TEAM_MEMBER"
export const UPDATE_TEAM_MEMBER_ERROR = "UPDATE_TEAM_MEMBER_ERROR"

// Pop Ups
export const ADD_POP_UP = "ADD_POP_UP"
export const ADD_POP_UP_ERROR = "ADD_POP_UP_ERROR"
export const GET_ALL_POP_UPS_PAGINATED = "GET_ALL_POP_UPS_PAGINATED"
export const GET_ALL_POP_UPS_PAGINATED_ERROR = "GET_ALL_POP_UPS_PAGINATED_ERROR"
export const COUNT_POP_UPS = "COUNT_POP_UPS"
export const COUNT_POP_UPS_ERROR = "COUNT_POP_UPS_ERROR"
export const UPDATE_POP_UP = "UPDATE_POP_UP"
export const UPDATE_POP_UP_ERROR = "UPDATE_POP_UP_ERROR"
export const DELETE_POP_UP = "DELETE_POP_UP"
export const DELETE_POP_UP_ERROR = "DELETE_POP_UP_ERROR"
export const SELECT_POPUP = "SELECT_POPUP"
export const GET_ALL_PUBLISHED_POPUPS = "GET_ALL_PUBLISHED_POPUPS"
export const GET_ALL_PUBLISHED_POPUPS_ERROR = "GET_ALL_PUBLISHED_POPUPS_ERROR"
