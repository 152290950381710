import {
    DXP_CREATE_NEW_POP_UP,
    DXP_CREATE_NEW_POP_UP_ERROR,
    DXP_GET_ALL_POP_UPS_PAGINATED,
    DXP_GET_ALL_POP_UPS_PAGINATED_ERROR,
    DXP_GET_POP_UP_BY_ID,
    DXP_GET_POP_UP_BY_ID_ERROR,
    DXP_UPDATE_POP_UP,
    DXP_UPDATE_POP_UP_ERROR,
    DXP_DELETE_POP_UP,
    DXP_DELETE_POP_UP_ERROR,
    DXP_GET_ALL_PUBLISHED_POP_UPS,
    DXP_GET_ALL_PUBLISHED_POP_UPS_ERROR,

} from "../actions/types";


const initialState = {
    isLoadingPopUps: true,
    successPopUps: false,
    allPopUpsPaginated: [],
    publishedPopups: [],
    allPopUpsPaginatedCount: 0,
    popUpById: {},
};


export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case DXP_GET_ALL_PUBLISHED_POP_UPS:
            return {
                ...state,
                publishedPopups: payload
            };
        case DXP_GET_ALL_POP_UPS_PAGINATED:
            return {
                ...state,
                isLoadingPopUps: false,
                successPopUps: true,
                allPopUpsPaginated: payload.rows,
                allPopUpsPaginatedCount: payload.count,
            };
        case DXP_GET_POP_UP_BY_ID:
            return {
                ...state,
                isLoadingPopUps: false,
                successPopUps: true,
                popUpById: payload,
            };
        case DXP_CREATE_NEW_POP_UP:
        case DXP_UPDATE_POP_UP:
        case DXP_DELETE_POP_UP:
            return {
                ...state,
                isLoadingPopUps: false,
                successPopUps: true,
            };
        case DXP_GET_ALL_PUBLISHED_POP_UPS_ERROR:
            return {
                ...state,
                publishedPopups: []
            };
        case DXP_CREATE_NEW_POP_UP_ERROR:
        case DXP_GET_ALL_POP_UPS_PAGINATED_ERROR:
        case DXP_GET_POP_UP_BY_ID_ERROR:
        case DXP_UPDATE_POP_UP_ERROR:
        case DXP_DELETE_POP_UP_ERROR:
            return {
                ...state,
                isLoadingPopUps: false,
                successPopUps: false,
            };
        default:
            return state;
    }
}